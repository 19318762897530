import { TRPCClientError, createTRPCClient, httpBatchLink, httpLink } from "@trpc/client"
import { createTRPCReact } from "@trpc/react-query"

import { getCookieByName } from "@forento/shared/utilities/cookie"
import trpcTransformer from "@forento/shared/utilities/trpcTransformer"

import type { AppRoutes } from "../../../api/src/routes"

const trpcOptions = {
	links: [
		CONFIG.isDevelopment
			? httpLink({ url: "/api/_", transformer: trpcTransformer })
			: httpBatchLink({
					url: getCookieByName("forento-api-url") ?? "https://api.forento.io/_",
					transformer: trpcTransformer,
					fetch(url, options) {
						return fetch(url, { ...options, credentials: "include" })
					},
				}),
	],
}

const trpc = createTRPCClient<AppRoutes>(trpcOptions)

export const query = createTRPCReact<AppRoutes>()

export const queryTrpcClient = query.createClient(trpcOptions)

function isTRPCClientError(cause: unknown): cause is TRPCClientError<AppRoutes> {
	return cause instanceof TRPCClientError
}

export function getInputErrors(error: unknown): Record<string, string[]> {
	if (!isTRPCClientError(error)) return {}
	return error.data?.inputErrors ?? {}
}

export default trpc
