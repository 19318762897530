import { type FC, useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router"

import Form from "@forento/shared/components/Form"
import { getCookieByName } from "@forento/shared/utilities/cookie"

import Button, { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

import { signup } from ".."
import {
	Content,
	ErrorText,
	GoogleSigninButton,
	Inputs,
	InputsRow,
	NavigationButton,
	NavigationText,
	Title,
} from "../../Layout"
import ReferralCodeModal from "./ReferralCodeModal"

const Regular: FC = () => {
	const user = useUser()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")

	const [isReferralCodeModalOpen, setReferralCodeModalOpen] = useState(false)
	const [referralCode, setReferralCode] = useState(searchParams.get("r"))
	const referrer = query.referral.getCode.useQuery(referralCode!, { enabled: referralCode !== null })
	const [error, setError] = useState<string>()
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSignup = async () => {
		setSubmitting(true)
		setError(undefined)
		const result = await signup({
			firstName,
			lastName,
			email,
			password,
			passwordConfirmation,
			referralCode: referrer?.data?.code,
			source: searchParams.get("s") ?? getCookieByName("s") ?? undefined,
		})
		setSubmitting(false)
		if (result.status !== "success") {
			setError(result.error)
			return
		}

		await user.reload()

		navigate(routes.account.createPlatform())
	}

	return (
		<>
			<ReferralCodeModal
				isOpen={isReferralCodeModalOpen}
				onClose={() => setReferralCodeModalOpen(false)}
				onSubmit={setReferralCode}
			/>
			<Content as={Form} onSubmit={handleSignup}>
				<Title>Register a new account</Title>
				{referrer.error ? (
					<p>Failed to check for referral code.</p>
				) : referralCode !== null && referrer.isPending ? (
					<PartialLoadingPage />
				) : (
					<>
						{error !== undefined && <ErrorText>{error}</ErrorText>}
						<Inputs>
							<InputsRow>
								<InputField
									label="First name"
									placeholder=""
									value={firstName}
									onChange={setFirstName}
									autoComplete="given-name"
								/>

								<InputField
									label="Last name"
									value={lastName}
									onChange={setLastName}
									autoComplete="family-name"
								/>
							</InputsRow>

							<InputField
								inputType="email"
								label="Email"
								value={email}
								onChange={setEmail}
								autoComplete="email"
							/>

							<InputField
								inputType="password"
								label="Password"
								value={password}
								onChange={setPassword}
								autoComplete="new-password"
							/>

							<InputField
								inputType="password"
								label="Confirm password"
								value={passwordConfirmation}
								onChange={setPasswordConfirmation}
								autoComplete="new-password"
							/>
						</Inputs>

						{referrer.data ? (
							<p>Referred by: {referrer.data.label}</p>
						) : (
							<Button variant="link" onClick={() => setReferralCodeModalOpen(true)}>
								Add referral code
							</Button>
						)}

						<NavigationText>
							By signing up you agree to our{" "}
							<NavigationButton onClick="https://forento.io/policy/terms-of-service" newTab>
								Terms of Service
							</NavigationButton>
							.
						</NavigationText>

						<SubmitButton variant="primary" isLoading={isSubmitting}>
							Sign up
						</SubmitButton>

						<GoogleSigninButton text="signup" />

						<NavigationText>
							Already have an account?{" "}
							<NavigationButton onClick={routes.account.signin()}>Sign in</NavigationButton>
						</NavigationText>
					</>
				)}
			</Content>
		</>
	)
}

export default Regular
