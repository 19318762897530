import { type FC } from "react"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"

import Button from "~/components/Button"
import { CheckmarkIcon, CloseIcon, QuestionIcon } from "~/components/Icon"
import { DefaultModal, ModalButtons, ModalPadding, ModalText, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { dangerColor, successColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

type Props = { eventId: number; isOpen: boolean; onClose(): void }
const AttendeesModal: FC<Props> = ({ eventId, isOpen, onClose }) => {
	const { data, error } = query.event.listAttendees.useQuery(eventId, { enabled: isOpen })

	return (
		<DefaultModal isOpen={isOpen}>
			<ModalTitle>Attendees</ModalTitle>
			{error ? (
				<ModalText>Failed to load attendees. Please try again later.</ModalText>
			) : !data ? (
				<PartialLoadingPage />
			) : (
				<Attendees>
					{data
						.filter(x => x.response === "attending")
						.map(attendee => (
							<Attendee key={attendee.user.id}>
								<AttendingIcon />
								<CommunityProfileAvatar size={32} profile={attendee.user} />
								<AttendeeName onClick={routes.user.detail(attendee.user.id)}>
									{attendee.user.firstName} {attendee.user.lastName}
								</AttendeeName>
							</Attendee>
						))}
					{data
						.filter(x => x.response === "interested")
						.map(attendee => (
							<Attendee key={attendee.user.id}>
								<InterestedIcon />
								<CommunityProfileAvatar size={32} profile={attendee.user} />
								<AttendeeName onClick={routes.user.detail(attendee.user.id)}>
									{attendee.user.firstName} {attendee.user.lastName}
								</AttendeeName>
							</Attendee>
						))}
					{data
						.filter(x => x.response === "not-interested")
						.map(attendee => (
							<Attendee key={attendee.user.id}>
								<NotInterestedIcon />
								<CommunityProfileAvatar size={32} profile={attendee.user} />
								<AttendeeName onClick={routes.user.detail(attendee.user.id)}>
									{attendee.user.firstName} {attendee.user.lastName}
								</AttendeeName>
							</Attendee>
						))}
				</Attendees>
			)}
			<ModalButtons>
				<Button variant="primary" onClick={onClose}>
					Close
				</Button>
			</ModalButtons>
		</DefaultModal>
	)
}

const Attendees = styled(ModalPadding)`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Attendee = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`

const AttendingIcon = styled(CheckmarkIcon)`
	width: 24px;
	height: 24px;
	color: ${successColor};
`

const InterestedIcon = styled(QuestionIcon)`
	width: 24px;
	height: 24px;
	color: #ffb800;
`

const NotInterestedIcon = styled(CloseIcon)`
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

const AttendeeName = styled(Button)`
	font-weight: 600;
	font-size: 14px;
`

export default AttendeesModal
