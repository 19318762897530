import { type FC } from "react"
import styled from "styled-components"
import tinycolor from "tinycolor2"

import { type PersonalizedThinEvent } from "@forento/shared/models/event"
import { toPriceString } from "@forento/shared/utilities/currency"

import { dangerColor, primaryColor, successColor } from "~/utilities/styles"

const Event: FC<{ event: PersonalizedThinEvent }> = ({ event }) => (
	<Container>
		<Title>{event.title}</Title>
		<Tags>
			{event.response === "attending" ? (
				<Tag $color={successColor}>Attending</Tag>
			) : event.response === "interested" ? (
				<Tag $color={primaryColor}>Interested</Tag>
			) : event.response === "not-interested" ? (
				<Tag $color={dangerColor}>Not interested</Tag>
			) : (
				<Tag $color={primaryColor}>Not responded</Tag>
			)}
			<Tag $color={successColor}>
				{event.price !== null
					? toPriceString(event.price)
					: event.membershipIds.length > 0
						? "Membership"
						: "Free"}
			</Tag>
		</Tags>
	</Container>
)

const Container = styled.div`
	background-color: #fbfbf9;
	border: 1px solid #eaeaea;
	border-radius: 12px;
	padding: 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
`

const Title = styled.p`
	font-weight: 600;
	font-size: 14px;
`

const Tags = styled.div`
	display: flex;
	gap: 16px;
`

const Tag = styled.div<{ $color: string }>`
	font-weight: 600;
	font-size: 12px;
	line-height: 1;
	color: ${props => props.$color};
	background-color: ${props => tinycolor(props.$color).setAlpha(0.05).toString()};
	border-radius: 12px;
	padding: 6px 12px;
`

export default Event
