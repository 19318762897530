import { useState, type FC } from "react"
import styled, { css } from "styled-components"

import { type BlogPost as BlogPostType, type RecentEvent } from "@forento/shared/models/dashboard"

import Button from "~/components/Button"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { primaryColor, primaryLightColor } from "~/utilities/styles"

import BlogPost from "./BlogPost"
import Event from "./Event"

type TabId = "creatorHighlights" | "productUpdates"
const tabs = new Map<TabId, { label: string; dubSlug: string }>([
	["creatorHighlights", { label: "Creator highlights", dubSlug: "dash-as-creatorhighlights" }],
	["productUpdates", { label: "Product updates", dubSlug: "dash-as-productupdates" }],
])

type Props = {
	data:
		| { status: "error" | "loading" }
		| {
				status: "success"
				events: RecentEvent[]
				creatorHighlights: BlogPostType[]
				productUpdates: BlogPostType[]
		  }
}
const AsideContent: FC<Props> = ({ data }) => {
	const [activeTab, setActiveTab] = useState<TabId>("creatorHighlights")

	return (
		<Container>
			<Title>Recent events</Title>
			{data.status === "success" ? (
				<Sections>
					<Section>
						{data.events.map((event, index) => (
							<Event key={index} event={event} />
						))}
					</Section>
					<Section>
						<BlogHeader>
							{Array.from(tabs).map(([id, tab]) => (
								<BlogTab key={id} $isActive={activeTab === id} onClick={() => setActiveTab(id)}>
									{tab.label}
								</BlogTab>
							))}
						</BlogHeader>
						<BlogPosts>
							{data[activeTab].map(post => (
								<BlogPost key={post.link} post={post} />
							))}
							<BlogButton key="button" onClick={`https://forento.link/${tabs.get(activeTab)!.dubSlug}`}>
								See more posts
							</BlogButton>
						</BlogPosts>
					</Section>
				</Sections>
			) : data.status === "loading" ? (
				<PartialLoadingPage />
			) : null}
		</Container>
	)
}

const Container = styled.div`
	flex: 0.5;
	background-color: ${primaryLightColor};
	padding: 34px 24px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 32px;
	margin-bottom: 20px;
`

const Sections = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Section = styled.div`
	background-color: white;
	border-radius: 24px;
`

const BlogHeader = styled.div`
	padding: 24px 24px 0;
	border-bottom: 1px solid #efefef;
	display: flex;
	gap: 16px;
`

const BlogTab = styled(Button)<{ $isActive: boolean }>`
	color: #7d7d7d;
	font-size: 16px;
	font-weight: 600;
	padding-bottom: 8px;

	${props =>
		props.$isActive &&
		css`
			color: black;
			padding-bottom: 6px;
			border-bottom: 2px solid #8800ff;
		`}
`

const BlogPosts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 24px;
`

const BlogButton = styled(Button)`
	color: ${primaryColor};
	font-size: 16px;
	font-weight: bold;
`

export default AsideContent
