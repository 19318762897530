import { useCallback, type FC } from "react"
import styled from "styled-components"

import { type IntendedPageType, type CoursePageInputType } from "@forento/shared/models/course"
import { type VideoCaptionLanguageCode } from "@forento/shared/models/video"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import { DefaultModal } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import { query } from "~/utilities/trpc"

import EditCustomPageModalContent from "./EditCustomPageModalContent"
import EditInputPageModalContent from "./EditInputPageModalContent"
import EditQuizPageModalContent from "./EditQuizPageModalContent"
import EditTextPageModalContent from "./EditTextPageModalContent"
import EditVideoPageModalContent from "./EditVideoPageModalContent"
import EditVimeoPageModalContent from "./EditVimeoPageModalContent"
import EditYouTubePageModalContent from "./EditYouTubePageModalContent"

type Props = {
	isOpen: boolean
	type: IntendedPageType
	page: {
		id: number
		title: string
		text: string | null
		image: { filePath: string; contain: boolean } | null
		quiz: { answers: { text: string; isCorrect: boolean }[] | null } | null
		video:
			| { host: "apiVideo"; apiVideoId: string }
			| { host: "bunny"; bunnyVideoId: string; thumbnailUrl: string | null; captions: VideoCaptionLanguageCode[] }
			| null
		youTubeVideoId: string | null
		vimeoVideoId: string | null
		inputType: CoursePageInputType | null
		attachments: {
			id: number
			fileType: string
			label: string
			url: string
		}[]
		links: {
			id: number
			label: string
			url: string
		}[]
	}
	onClose(args?: { reloadCourse?: boolean }): Promise<void>
}
const EditPageModal: FC<Props> = ({ isOpen, type, page, onClose }) => {
	const alert = useAlert()

	const update = query.course.updatePage.useMutation({
		async onSuccess() {
			await onClose({ reloadCourse: true })
		},
		async onError() {
			await alert.show("Error", "Failed to save page. Please try again.")
		},
	})

	const remove = query.course.deletePage.useMutation({
		async onSuccess() {
			await onClose({ reloadCourse: true })
		},
		async onError() {
			await alert.show("Error", "Failed to delete page. Please try again.")
		},
	})

	const handleSavePage = useCallback(
		(data: {
			title: string
			text: string | null
			youTubeVideoId?: string
			vimeoVideoId?: string
			thumbnailDataUrl?: string | null
			imageDataUrl?: string | null
			containImage?: boolean
			removeCaptions?: VideoCaptionLanguageCode[]
			addCaptions?: { language: VideoCaptionLanguageCode; base64: string }[]
		}) => {
			update.mutate({ id: page.id, data })
		},
		[page.id, update],
	)

	const handleDeletePage = useCallback(() => {
		remove.mutate(page.id)
	}, [page.id, remove])

	return (
		<DefaultModal isOpen={isOpen} width={600}>
			<Header>Edit page</Header>
			<Content>
				{type === "video" ? (
					<EditVideoPageModalContent
						key={page.id}
						page={page}
						isSaving={update.isPending}
						isDeleting={remove.isPending}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
					/>
				) : type === "youtube" ? (
					<EditYouTubePageModalContent
						key={page.id}
						page={page}
						isSaving={update.isPending}
						isDeleting={remove.isPending}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
					/>
				) : type === "vimeo" ? (
					<EditVimeoPageModalContent
						key={page.id}
						page={page}
						isSaving={update.isPending}
						isDeleting={remove.isPending}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
					/>
				) : type === "text" ? (
					<EditTextPageModalContent
						key={page.id}
						page={page}
						isSaving={update.isPending}
						isDeleting={remove.isPending}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
					/>
				) : type === "input" ? (
					<EditInputPageModalContent
						key={page.id}
						page={page}
						isSaving={update.isPending}
						isDeleting={remove.isPending}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
					/>
				) : type === "quiz" ? (
					<EditQuizPageModalContent
						key={page.id}
						page={page}
						isSaving={update.isPending}
						isDeleting={remove.isPending}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
					/>
				) : type === "custom" ? (
					<EditCustomPageModalContent
						key={page.id}
						page={page}
						isSaving={update.isPending}
						isDeleting={remove.isPending}
						onClose={onClose}
						onSavePage={handleSavePage}
						onDeletePage={handleDeletePage}
					/>
				) : (
					exhaustiveGuard(type)
				)}
			</Content>
		</DefaultModal>
	)
}

const Header = styled.div`
	font-weight: 600;
	font-size: 18px;
	color: #101828;
	margin: 0 24px;
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 24px 24px 0;
`

export default EditPageModal
