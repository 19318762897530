import { Navigate } from "react-router"
import styled from "styled-components"

import CommunityPosts from "~/components/CommunityPosts"
import Layout, { PageTitle } from "~/components/Layout"
import { RouteTabs } from "~/components/Tabs"
import { useUser } from "~/contexts/UserContext"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import routes from "~/utilities/routes"

import Channels from "./Channels"
import CommunityProfiles from "./CommunityProfiles"
import CommunitySettings from "./CommunitySettings"
import CreateCommunityProfile from "./CreateCommunityProfile"
import Leaderboard from "./Leaderboard"
import Messages from "./Messages"
import PostCategories from "./PostCategories"

const header = <PageTitle>Community</PageTitle>

const CommunityPage: React.FC = () => {
	const user = useUser()
	const platform = user.user!.platform!

	if (platform.community === null) return <Navigate to={routes.community.index()} />

	return (
		<Layout>
			{header}
			{user.user!.communityProfileId === null ? (
				<CreateCommunityProfile />
			) : (
				<RouteTabs
					tabs={[
						{
							title: "Posts",
							route: routes.community.posts(),
							content: (
								<TabContent>
									<CommunityPosts />
								</TabContent>
							),
						},
						{
							title: "Channels",
							route: routes.community.channels(),
							content: (
								<TabContent>
									<Channels />
								</TabContent>
							),
						},
						{
							title: "Post categories",
							route: routes.community.postCategories(),
							content: (
								<TabContent>
									<PostCategories />
								</TabContent>
							),
						},
						{
							title: "Profiles",
							route: routes.community.profiles(),
							content: (
								<TabContent>
									<CommunityProfiles />
								</TabContent>
							),
						},
						...(platform.community.isGamificationEnabled
							? [
									{
										title: "Leaderboard",
										route: routes.community.leaderboard(),
										content: (
											<TabContent>
												<Leaderboard />
											</TabContent>
										),
									},
								]
							: []),
						{
							title: "Messages",
							route: routes.community.messages(),
							content: (
								<TabContent>
									<Messages />
								</TabContent>
							),
						},
						{
							title: "Settings",
							route: routes.community.settings(),
							content: (
								<TabContent>
									<CommunitySettings />
								</TabContent>
							),
						},
					]}
				/>
			)}
		</Layout>
	)
}
const TabContent = styled.div`
	margin-top: 20px;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

export const Section = styled.div`
	padding: 48px 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

export default withAccessRequirement("community", header, CommunityPage)
