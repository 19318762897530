import { useState, type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import Tooltip from "@forento/shared/components/Tooltip"
import { toDateTimeString } from "@forento/shared/utilities/date"
import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import Layout, { PageBreadcrumb, PageHeader, PageHeaderGroup } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

import Course from "./Course"
import DeleteModal from "./DeleteModal"
import Downloadable from "./Downloadable"
import Event from "./Event"
import PageInput from "./PageInput"
import Video from "./Video"

const UserDetailPage: FC = () => {
	const userId = parseNumber(useParams().userId ?? "") ?? -1
	const platform = usePlatform()!

	const { data: user, error, refetch } = query.user.getDetailedUserById.useQuery(userId)

	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

	if (user === null) return <Navigate to={routes.user.index()} />

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb
					path={[{ title: "Users", link: routes.user.index() }]}
					title={!user ? "..." : `${user.firstName} ${user.lastName}`}
				/>
				{!error && user && (
					<PageHeaderGroup>
						{platform.community &&
							(user.communityProfileId !== null ? (
								<Button variant="primary" onClick={routes.community.profile(user.communityProfileId)}>
									Go to community profile
								</Button>
							) : (
								<Tooltip tooltip="User is not registered in the community">
									<Button variant="primary" isDisabled>
										Go to community profile
									</Button>
								</Tooltip>
							))}
						<Button variant="primary-danger" onClick={() => setDeleteModalOpen(true)}>
							Delete
						</Button>
					</PageHeaderGroup>
				)}
			</PageHeader>
			{error ? (
				<p>Failed to load user.</p>
			) : !user ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<DeleteModal
						isOpen={isDeleteModalOpen}
						user={{
							id: user.id,
							hasMembership: user.membership !== null,
							hasPurchasedContent: [...user.courses, ...user.downloadables, ...user.videos].some(
								x => x.status === "unlocked" && x.unlockedCause === "purchased",
							),
						}}
						onClose={() => setDeleteModalOpen(false)}
						onDeleted={refetch}
					/>
					<Header>
						<Avatar profile={user} />
						<Details>
							<Name>
								{user.firstName} {user.lastName}
							</Name>
							<Email>{user.email}</Email>
						</Details>
					</Header>
					{user.phoneNumber && (
						<Group>
							<GroupTitle>Phone number</GroupTitle>
							<GroupValue>{user.phoneNumber}</GroupValue>
						</Group>
					)}
					<Group>
						<GroupTitle>Register date</GroupTitle>
						<GroupValue>{toDateTimeString(user.registerDate)}</GroupValue>
					</Group>
					<Group>
						<GroupTitle>Membership</GroupTitle>
						<GroupValue>{user.membership?.title ?? "None"}</GroupValue>
					</Group>

					{user.courses.length > 0 && (
						<Group>
							<GroupTitle>Courses</GroupTitle>
							<GroupItems>
								{user.courses.map(course => (
									<Course key={course.id} course={course} userId={user.id} reload={refetch} />
								))}
							</GroupItems>
						</Group>
					)}

					{user.videos.length > 0 && (
						<Group>
							<GroupTitle>Videos</GroupTitle>
							<GroupItems>
								{user.videos.map(video => (
									<Video key={video.id} video={video} userId={userId} reload={refetch} />
								))}
							</GroupItems>
						</Group>
					)}

					{user.downloadables.length > 0 && (
						<Group>
							<GroupTitle>Downloadables</GroupTitle>
							<GroupItems>
								{user.downloadables.map(downloadable => (
									<Downloadable
										key={downloadable.id}
										downloadable={downloadable}
										userId={userId}
										reload={refetch}
									/>
								))}
							</GroupItems>
						</Group>
					)}

					{user.events.length > 0 && (
						<Group>
							<GroupTitle>Events</GroupTitle>
							<GroupItems>
								{user.events.map(event => (
									<Event key={event.id} event={event} />
								))}
							</GroupItems>
						</Group>
					)}

					{user.inputs.chapters.length > 0 && (
						<Group>
							<GroupTitle>Course inputs</GroupTitle>
							<InputPages>
								{user.inputs.chapters
									.flatMap(chapter =>
										chapter.pages.map(page => ({ ...page, chapterTitle: chapter.title })),
									)
									.map(page => (
										<PageInput key={page.id} page={page} chapterTitle={page.chapterTitle} />
									))}
							</InputPages>
						</Group>
					)}
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Header = styled.div`
	display: flex;
	gap: 16px;
`

const Avatar = styled(CommunityProfileAvatar).attrs({ size: 56 })`
	box-sizing: content-box;
	border: 4px solid #ede7fb;
`

const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const Name = styled.div`
	font-size: 18px;
	font-weight: 600;
`

const Email = styled.div`
	font-size: 16px;
	color: #667085;
`

const Group = styled.div``

const GroupTitle = styled.h2`
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 8px;
`

const GroupValue = styled.h2`
	font-size: 14px;
`

const GroupItems = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const InputPages = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

export default UserDetailPage
