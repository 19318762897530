import { type FC } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

import { VideoFileIcon } from "~/components/Icon"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { primaryColor } from "~/utilities/styles"

type Props = { onUpload: (file: File) => Promise<void>; progress: number | null }
const VideoUploader: FC<Props> = ({ onUpload, progress }) => {
	const dropzone = useDropzone({
		accept: { "video/*": [] },
		async onDrop(acceptedFiles: File[]) {
			if (acceptedFiles.length === 0) return
			await onUpload(acceptedFiles[0])
		},
	})

	return (
		<Dropzone {...dropzone.getRootProps()}>
			{progress === null && <input {...dropzone.getInputProps()} />}
			{getContent({
				isDragActive: dropzone.isDragActive,
				isDragReject: dropzone.isDragReject,
				progress,
			})}
		</Dropzone>
	)
}

const getContent = ({
	isDragActive,
	isDragReject,
	progress,
}: {
	isDragActive: boolean
	isDragReject: boolean
	progress: number | null
}) => {
	if (progress !== null) {
		return (
			<>
				<PartialLoadingPage />
				<ProgressBarWrapper>
					<ProgressBarContainer>
						<ProgressBar style={{ width: `${progress * 100}%` }} />
					</ProgressBarContainer>
					<InfoText>{Math.floor(progress * 100)}%</InfoText>
				</ProgressBarWrapper>
			</>
		)
	}

	if (isDragActive) {
		return <InfoText>{isDragReject ? "That file type is not supported" : `Drop video file here`}</InfoText>
	}

	return (
		<>
			<Icon as={VideoFileIcon} />
			<InfoText>Click here or drag and drop to upload</InfoText>
		</>
	)
}

const Dropzone = styled.div`
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 32px;
	cursor: pointer;
	position: relative;
`

const Icon = styled.div`
	width: 64px;
	height: 64px;
	color: ${primaryColor};
`

const ProgressBarWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
`

const ProgressBarContainer = styled.div`
	width: 100%;
	max-width: 600px;
	height: 30px;
	border: 1px solid #cdcdcd;
	border-radius: 8px;
	overflow: hidden;
`

const ProgressBar = styled.div`
	height: 100%;
	background-color: #cdcdcd;
	border-radius: 8px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	transition: 0.3s ease-in-out;
`

const InfoText = styled.p`
	font-weight: 500;
	font-size: 16px;
	text-align: center;
`

export default VideoUploader
