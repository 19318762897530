import { type ReactNode, type FC } from "react"
import styled from "styled-components"

const CompletedTask: FC<{ task: { icon: ReactNode; title: string } }> = ({ task }) => (
	<Container>
		{task.icon}
		<Title>{task.title}</Title>
	</Container>
)

const Container = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 8px 24px;
	display: flex;
	align-items: center;
	gap: 16px;
`

const Title = styled.p`
	font-weight: 600;
	font-size: 16px;
	text-decoration: line-through;
`

export default CompletedTask
