import { type FC, type ReactNode } from "react"
import { Link } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import { CrownIcon } from "~/components/Icon"
import routes from "~/utilities/routes"
import { primaryColor } from "~/utilities/styles"

type Props = {
	label: string
	description: string
	actions?: ReactNode
	isLocked?: boolean
}
const ListItem: FC<Props> = ({ label, description, actions, isLocked }) => {
	if (isLocked) {
		return (
			<PremiumContainer>
				<div>
					<Title>{label}</Title>
					<Subtitle>{description}</Subtitle>
				</div>
				<PremiumFeature>
					<PremiumIcon />
					<p>
						{label} is a premium feature. Visit{" "}
						<Link to={routes.settings.billing.plans()}>Billing & Plans</Link> to choose a plan that includes
						{label.toLowerCase()}.
					</p>
				</PremiumFeature>
			</PremiumContainer>
		)
	}

	return (
		<Container>
			<div>
				<Title>{label}</Title>
				<Subtitle>{description}</Subtitle>
			</div>
			<Actions>{actions}</Actions>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
	display: flex;
	justify-content: space-between;
	gap: 16px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
	text-align: left;
`

const Subtitle = styled.p`
	font-size: 16px;
	text-align: left;
`

const PremiumContainer = styled.div`
	background-color: ${new Values("#fbfaf8").shade(5).hexString()};
	border: 2px solid ${primaryColor};
	border-radius: 8px;
	padding: 21px;
	display: flex;
	justify-content: space-between;
	gap: 16px;

	${Title} {
		color: ${new Values("#000").tint(30).hexString()};
	}

	${Subtitle} {
		color: ${new Values("#000").tint(30).hexString()};
	}
`

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const PremiumFeature = styled.div`
	flex: 0 1 600px;
	color: ${primaryColor};
	display: flex;
	align-items: center;
	gap: 8px;
`

const PremiumIcon = styled(CrownIcon)`
	flex: 0 0 auto;
	width: 24px;
	height: 24px;
`

export default ListItem
