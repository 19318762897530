import { type ReactNode, type FC } from "react"
import styled from "styled-components"

import { type RecentEvent } from "@forento/shared/models/dashboard"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import Button from "~/components/Button"
import { toRelativeDateString } from "~/utilities/date"
import routes from "~/utilities/routes"

const Event: FC<{ event: RecentEvent }> = ({ event }) => (
	<Container>
		<Text>{getEventText(event)}</Text>
		<Date>{toRelativeDateString(event.date)}</Date>
	</Container>
)

function getEventText(event: RecentEvent): ReactNode {
	if (event.type === "community-post") {
		return (
			<>
				<Link onClick={routes.community.profile(event.profile.id)}>{event.profile.name}</Link> created a{" "}
				<Strong>community post</Strong>
			</>
		)
	}

	const userLink = <Link onClick={routes.user.detail(event.user.id)}>{event.user.name}</Link>
	switch (event.type) {
		case "user":
			return <>{userLink} signed up</>
		case "order":
			return (
				<>
					{userLink} purchased <Strong>{event.productName}</Strong>
				</>
			)
		case "subscription":
			return (
				<>
					{userLink} subscribed to <Strong>{event.productName}</Strong>
				</>
			)
		case "certificate":
		case "community-profile":
			return (
				<>
					{userLink} created a <Strong>community profile</Strong>
				</>
			)
		default:
			exhaustiveGuard(event)
	}
}

const Container = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 4px;

	&:not(:last-child) {
		padding-bottom: 23px;
		border-bottom: 1px solid #efefef;
	}
`

const Text = styled.div`
	color: #7d7d7d;
	font-size: 16px;
	font-weight: 500;
`

const Link = styled(Button).attrs({ variant: "link" })`
	display: inline;
	color: black;
	font-weight: 500;
`

const Strong = styled.strong`
	color: black;
	font-weight: 500;
`

const Date = styled.div`
	font-size: 14px;
	color: #666;
`

export default Event
