import { type FC, useEffect, useState } from "react"

import Button from "~/components/Button"
import InputField, { useRichTextArea } from "~/components/InputField"
import { useAlert } from "~/contexts/AlertContext"

import Attachments from "./Attachments"
import Links from "./Links"
import { ButtonsGroup, ButtonsRow } from "./styles"

type Props = {
	page: {
		id: number
		title: string
		text: string | null
		youTubeVideoId: string | null
		attachments: { id: number; fileType: string; label: string; url: string }[]
		links: { id: number; label: string; url: string }[]
	}
	isSaving: boolean
	isDeleting: boolean
	onClose(): void
	onSavePage(data: { title: string; text: string | null; youTubeVideoId: string }): void
	onDeletePage(): void
}

const EditYouTubePageModalContent: FC<Props> = ({ page, isSaving, isDeleting, onClose, onSavePage, onDeletePage }) => {
	const alert = useAlert()

	const [title, setTitle] = useState(page.title)
	const text = useRichTextArea({ label: "Text", initialValue: page.text, features: { headings: true, colors: true } })
	const [youTubeUrl, setYouTubeUrl] = useState("")

	useEffect(() => {
		setTitle(page.title)
		setYouTubeUrl(page.youTubeVideoId ? `https://www.youtube.com/watch?v=${page.youTubeVideoId}` : "")
	}, [page])

	async function handleSave() {
		const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
		const match = youTubeUrl.match(regExp)
		const youTubeVideoId = match?.[1]
		if (youTubeVideoId === undefined) {
			await alert.show(
				"Invalid YouTube URL",
				"You have provided an invalid YouTube URL. Please copy the URL from the address bar when watching a YouTube video.",
			)
			return
		}

		onSavePage({ title, text: text.exportEditorState(), youTubeVideoId })
	}

	return (
		<>
			<InputField label="Title" value={title} onChange={setTitle} />
			{text.element}
			<Attachments pageId={page.id} attachments={page.attachments} />
			<Links pageId={page.id} links={page.links} />
			<InputField label="YouTube URL" value={youTubeUrl} onChange={setYouTubeUrl} />
			<ButtonsRow>
				<ButtonsGroup>
					<Button variant="secondary" onClick={onClose} isDisabled={isSaving || isDeleting}>
						Cancel
					</Button>
					<Button
						variant="primary"
						isDisabled={title.trim().length === 0 || isDeleting}
						isLoading={isSaving}
						onClick={handleSave}
					>
						Save
					</Button>
				</ButtonsGroup>
				<Button variant="primary-danger" onClick={onDeletePage} isDisabled={isSaving} isLoading={isDeleting}>
					Delete
				</Button>
			</ButtonsRow>
		</>
	)
}

export default EditYouTubePageModalContent
