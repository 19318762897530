import {
	IconBell,
	IconBrandStripe,
	IconDeviceDesktop,
	IconDiscount,
	IconDroplet,
	IconLink,
	IconMail,
	IconPackage,
	IconPackages,
	IconSchool,
	IconUserPlus,
	IconUsersGroup,
	IconVocabulary,
} from "@tabler/icons-react"
import { type ReactNode, type FC, useState, useEffect } from "react"
import styled from "styled-components"

import Collapse from "@forento/shared/components/Collapse"

import Button from "~/components/Button"
import { ChevronIcon } from "~/components/Icon"
import { DefaultModal, ModalButtons, ModalTitle } from "~/components/Modal"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { cardColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

import CompletedTask from "./CompletedTask"
import UpcomingTask from "./UpcomingTask"

type TaskData = {
	hasWelcomeEmail: boolean
	productCount: number
	bundleCount: number
	creatorCount: number
	hasChapterDripping: boolean
	hasCommunityChannels: boolean
	hasDiscountCodes: boolean
	hasUpdatedNotifications: boolean
}
export type Task = {
	title: string
	helpLink: { label: string; dubSlug: string }
	icon: ReactNode
	onClick: string | (() => void)
	isCompleted: boolean
	isVisible?: boolean
}

type Props = { isDismissed: boolean } & TaskData
const Onboarding: FC<Props> = ({ isDismissed, ...taskData }) => {
	const [isCollapsed, setCollapsed] = useState(isDismissed)
	const [hasCollapsedChanged, setCollapseChanged] = useState(false)

	const { mutate } = query.platform.update.useMutation({
		onError: error => console.error(error),
	})

	useEffect(() => {
		if (hasCollapsedChanged) {
			mutate({ isOnboardingDismissed: isCollapsed })
		}
	}, [mutate, isCollapsed, hasCollapsedChanged])

	return (
		<Container>
			<Header>
				<Title>Getting started</Title>
				<DismissButton
					onClick={() => {
						setCollapsed(current => !current)
						setCollapseChanged(true)
					}}
				>
					<DismissIcon direction={isCollapsed ? "down" : "up"} />
				</DismissButton>
			</Header>
			<Collapse isCollapsed={isCollapsed}>
				<Tasks taskData={taskData} />
			</Collapse>
		</Container>
	)
}

type TasksProps = { taskData: TaskData }
const Tasks: FC<TasksProps> = ({ taskData }) => {
	const platform = usePlatform()!

	const [isProductModalOpen, setProductModalOpen] = useState(false)

	const tasks: Task[] = [
		{
			title: "Create your first course",
			helpLink: { label: "Learn how to create a course", dubSlug: "dash-qs-course" },
			icon: <IconVocabulary />,
			onClick: routes.course.index(),
			isCompleted: platform.content.courses,
		},
		{
			title: "Customize your academy",
			helpLink: { label: "Learn how to customize", dubSlug: "dash-qs-customize" },
			icon: <IconSchool />,
			onClick: routes.settings.platform(),
			isCompleted: platform.logo !== null,
		},
		{
			title: "Connect a custom domain",
			helpLink: { label: "Connecting a domain", dubSlug: "dash-qs-domain" },
			icon: <IconLink />,
			onClick: routes.settings.domain.index(),
			isCompleted: !platform.domain.platform.endsWith("forento.app"),
			isVisible: platform.plan.access.customDomain,
		},
		{
			title: "Connect a payment gateway",
			helpLink: { label: "Quick start guide", dubSlug: "dash-qs-payment" },
			icon: <IconBrandStripe />,
			onClick: routes.payments.index(),
			isCompleted: Boolean(platform.paymentGateway?.isEnabled),
		},
		{
			title: "Set up a welcome email for new users",
			helpLink: { label: "Learn how to set up a welcome email", dubSlug: "dash-qs-welcome-email" },
			icon: <IconMail />,
			onClick: routes.settings.studentEmail.index(),
			isCompleted: taskData.hasWelcomeEmail,
		},
		{
			title: "Create a new product",
			helpLink: { label: "Learn how to create a product", dubSlug: "dash-qs-product" },
			icon: <IconPackage />,
			onClick: () => setProductModalOpen(true),
			isCompleted: taskData.productCount > 1,
			isVisible: platform.content.courses,
		},
		{
			title: "Create your website",
			helpLink: { label: "Website setup guide", dubSlug: "dash-qs-website" },
			icon: <IconDeviceDesktop />,
			onClick: routes.main.websiteEditor(),
			isCompleted: platform.domain.landingPage !== null,
			isVisible: platform.plan.access.landingPage,
		},
		{
			title: "Set up notifications",
			helpLink: { label: "Learn how to set up notifications", dubSlug: "dash-qs-notifications" },
			icon: <IconBell />,
			onClick: routes.settings.notification(),
			isCompleted: taskData.hasUpdatedNotifications,
		},
		{
			title: "Invite team member",
			helpLink: { label: "Learn how to invite a team member", dubSlug: "dash-qs-invite-team-member" },
			icon: <IconUserPlus />,
			onClick: routes.settings.team(),
			isCompleted: taskData.creatorCount > 1,
			isVisible: (platform.plan.maxAdmins ?? Infinity) > 1,
		},
		{
			title: "Create a discount code",
			helpLink: { label: "Learn how to create a discount code", dubSlug: "dash-qs-discount-code" },
			icon: <IconDiscount />,
			onClick: routes.main.discountCodes(),
			isCompleted: taskData.hasDiscountCodes,
			isVisible: platform.paymentGateway?.type === "stripe",
		},
		{
			title: "Set up your community",
			helpLink: { label: "Learn how to set up a community", dubSlug: "dash-qs-community" },
			icon: <IconUsersGroup />,
			onClick: routes.community.index(),
			isCompleted: platform.community !== null,
			isVisible: platform.plan.access.community,
		},
		{
			title: "Create a bundle",
			helpLink: { label: "Learn how to create a bundle", dubSlug: "dash-qs-bundle" },
			icon: <IconPackages />,
			onClick: routes.bundle.index(),
			isCompleted: taskData.bundleCount > 1,
			isVisible: platform.plan.access.bundles,
		},
		{
			title: "Set up content dripping",
			helpLink: { label: "Learn how to set up content dripping", dubSlug: "dash-qs-drip" },
			icon: <IconDroplet />,
			onClick: routes.course.index(),
			isCompleted: taskData.hasChapterDripping,
			isVisible: platform.plan.access.chapterDripping,
		},
		{
			title: "Organize your community",
			helpLink: { label: "Learn how to organize your community", dubSlug: "dash-qs-community-organize" },
			icon: <IconUsersGroup />,
			onClick: routes.community.index(),
			isCompleted: taskData.hasCommunityChannels,
			isVisible: platform.plan.access.communityChannels,
		},
	]

	const visibleTasks = tasks.filter(task => task.isVisible ?? true)
	const upcomingTasks = visibleTasks.filter(task => !task.isCompleted)
	const completedTasks = visibleTasks.filter(task => task.isCompleted)

	return (
		<>
			<DefaultModal isOpen={isProductModalOpen}>
				<ModalTitle>Create a new product</ModalTitle>
				<ModalButtons $vertical>
					<Button variant="secondary" onClick={routes.course.index()}>
						Course
					</Button>
					<Button variant="secondary" onClick={routes.exam.index()}>
						Exam
					</Button>
					<Button variant="secondary" onClick={routes.video.create()}>
						Video
					</Button>
					<Button variant="secondary" onClick={routes.downloadable.create()}>
						Downloadable
					</Button>
					<Button variant="secondary" onClick={routes.sessions.create()}>
						Session
					</Button>
					<Button variant="secondary" onClick={routes.main.event()}>
						Event
					</Button>
					<Button variant="secondary" onClick={routes.bundle.index()}>
						Bundle
					</Button>
					<Button variant="primary" onClick={() => setProductModalOpen(false)}>
						Close
					</Button>
				</ModalButtons>
			</DefaultModal>
			<TasksList>
				{upcomingTasks.map((task, index) => (
					<UpcomingTask key={index} task={task} />
				))}
				{completedTasks.map((task, index) => (
					<CompletedTask key={index} task={task} />
				))}
			</TasksList>
		</>
	)
}

const Container = styled.div`
	width: 100%;
	background: linear-gradient(277deg, #e9e1ff 2%, ${cardColor.orange} 98%);
	border-radius: 24px;
	padding: 24px;
	display: flex;
	flex-direction: column;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
`

const Title = styled.h2`
	font-size: 24px;
	font-weight: 600;
	line-height: 1;
`

const DismissButton = styled(Button)`
	width: 24px;
	height: 24px;
`

const DismissIcon = styled(ChevronIcon)`
	width: 24px;
	height: 24px;
`

const TasksList = styled.div`
	padding-top: 16px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
`

export default Onboarding
