import { useCallback, useState, type FC } from "react"
import { Navigate, useParams } from "react-router"

import { parseNumber } from "@forento/shared/utilities/number"

import EditStudentEmail, { type EmailSaveData, type EmailContent } from "~/components/EditStudentEmail"
import EmailPreview from "~/components/EmailPreview"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

const EditProductAccessEmail: FC = () => {
	const emailId = parseNumber(useParams().emailId)

	const [previewContent, setPreviewContent] = useState<EmailContent | null>(null)

	const { data: layout, error: layoutError } = query.studentEmails.getLayout.useQuery()
	const { data: email, error: emailError, refetch } = query.studentEmails.getProductAccess.useQuery(emailId ?? -1)

	const handleSave = useCallback(
		async (data: EmailSaveData) => {
			if (email == null || data === null) return
			await trpc.studentEmails.updateProductAccess.mutate({
				id: email.id,
				data: { title: data.title, content: data.content },
			})
			await refetch()
		},
		[email, refetch],
	)
	if (email === null) {
		return <Navigate to={routes.settings.studentEmail.productAccess()} replace />
	}

	return (
		<Layout asideContent={previewContent && <EmailPreview {...previewContent} />}>
			<PageBreadcrumb
				title={email?.product.name ?? "..."}
				path={[
					{ title: "Settings", link: routes.settings.index() },
					{ title: "Student Emails", link: routes.settings.studentEmail.index() },
					{ title: "Product Access", link: routes.settings.studentEmail.productAccess() },
				]}
			/>
			{layoutError || emailError ? (
				<p>Failed to load product access email.</p>
			) : !layout || !email ? (
				<PartialLoadingPage />
			) : (
				<EditStudentEmail
					email={email}
					layout={layout.content}
					onSave={handleSave}
					onContentWithPlaceholderChange={setPreviewContent}
				/>
			)}
		</Layout>
	)
}

export default EditProductAccessEmail
