import { type FC, Fragment, useEffect, useMemo, useState } from "react"
import { Link } from "react-router"
import styled from "styled-components"

import { checkMjml } from "@forento/shared/utilities/mjml"
import { replacePlaceholders } from "@forento/shared/utilities/string"

import CodeEditor from "~/components/CodeEditor"
import InputField from "~/components/InputField"
import RadioButtons from "~/components/RadioButtons"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"

const mjml2html = import("mjml-browser")

const options = [
	{ id: "default", label: "Use default content" },
	{ id: "custom", label: "Use custom content (MJML)" },
	{ id: "custom-html", label: "Use manual custom content (HTML, advanced)" },
] as const
type OptionId = (typeof options)[number]["id"]

function mapContent(args: {
	selectedOptionId: OptionId
	layout: string
	defaultContent: string | null
	customContent: string
	customHtmlContent: string
}): { type: "mjml" | "html"; value: string; valueWithLayout: string } {
	if (args.selectedOptionId === "default" && args.defaultContent) {
		return {
			type: "mjml",
			value: args.defaultContent,
			valueWithLayout: args.layout.replace("{content}", args.defaultContent),
		}
	}
	if (args.selectedOptionId === "custom") {
		return {
			type: "mjml",
			value: args.customContent,
			valueWithLayout: args.layout.replace("{content}", args.customContent),
		}
	}
	return { type: "html", value: args.customHtmlContent, valueWithLayout: args.customHtmlContent }
}

export type EmailContent = { title: string; content: { type: "mjml" | "html"; value: string } }
type Email = {
	custom: EmailContent | null
	default?: { title: string; content: string }
	placeholders: { key: string; previewValue: string }[]
}
export type EmailSaveData = {
	title: string
	content: { type: "mjml" | "html"; value: string }
	placeholders: { key: string; value: string }[]
} | null
type Props = {
	email: Email
	layout: string
	onSave(data: EmailSaveData): Promise<void>
	onContentWithPlaceholderChange(data: EmailContent | null): void
}
const EditStudentEmail: FC<Props> = ({ email, layout, onSave, onContentWithPlaceholderChange }) => {
	const platform = useUser().user!.platform!
	const alert = useAlert()
	const toast = useToast()

	const emailType: OptionId =
		email.custom?.content.type === "html"
			? "custom-html"
			: email.custom?.content.type === "mjml"
				? "custom"
				: "default"

	const [selectedOptionId, setSelectedOptionId] = useState<OptionId>(emailType)
	const [customTitle, setCustomTitle] = useState(email.custom?.title ?? email.default?.title ?? "")
	const [customContent, setCustomContent] = useState(
		email.custom?.content?.type === "mjml" ? email.custom.content.value : (email.default?.content ?? ""),
	)
	const [customHtmlContent, setCustomHtmlContent] = useState(
		email.custom?.content?.type === "html" ? email.custom.content.value : "",
	)

	const mappedContent = useMemo(() => {
		return {
			title: customTitle.trim(),
			content: mapContent({
				selectedOptionId,
				layout: layout,
				defaultContent: email.default?.content ?? "",
				customContent,
				customHtmlContent,
			}),
			placeholders: [
				{ key: "platformName", value: platform.name },
				{ key: "primaryColor", value: platform.primaryColor.value },
				...email.placeholders.map(x => ({ key: x.key, value: x.previewValue })),
			],
		}
	}, [
		customContent,
		customHtmlContent,
		customTitle,
		email,
		layout,
		platform.name,
		platform.primaryColor.value,
		selectedOptionId,
	])

	const contentWithPlaceholders = useMemo(() => {
		if (mappedContent === null) return null
		return {
			title: replacePlaceholders({ text: mappedContent.title, placeholders: mappedContent.placeholders }),
			content: {
				type: mappedContent.content.type,
				value: replacePlaceholders({
					text: mappedContent.content.valueWithLayout,
					placeholders: mappedContent.placeholders,
				}),
			},
		}
	}, [mappedContent])

	useEffect(() => {
		onContentWithPlaceholderChange(contentWithPlaceholders)
	}, [contentWithPlaceholders, onContentWithPlaceholderChange])

	useEffect(() => {
		const isModified =
			emailType !== selectedOptionId ||
			(selectedOptionId === "custom" &&
				(customTitle !== (email.custom?.title ?? "") ||
					customContent !== (email.custom?.content?.value ?? ""))) ||
			(selectedOptionId === "custom-html" &&
				(customTitle !== (email.custom?.title ?? "") ||
					customHtmlContent !== (email.custom?.content?.value ?? "")))

		if (isModified) {
			toast.setUnsavedChanges(async () => {
				if (!email || !contentWithPlaceholders) return false
				if (
					selectedOptionId === "custom" &&
					(customTitle.trim().length === 0 || customContent.trim().length === 0)
				) {
					return false
				}
				if (
					selectedOptionId === "custom-html" &&
					(customTitle.trim().length === 0 || customHtmlContent.trim().length === 0)
				) {
					return false
				}
				if (selectedOptionId === "custom") {
					const mjmlStatus = checkMjml(contentWithPlaceholders.content.value, (await mjml2html).default)
					if (mjmlStatus.status === "invalid") {
						if (mjmlStatus.errors.length > 0) {
							await alert.show(
								"Invalid content",
								`The custom content is not a valid MJML.\n\n${mjmlStatus.errors.map(x => `- ${x}`).join("\n")}`,
							)
						} else {
							await alert.show("Invalid content", `The custom content is not a valid MJML.`)
						}
						return false
					}
				}

				try {
					if (mappedContent && selectedOptionId !== "default") {
						await onSave({
							title: mappedContent.title,
							content: { type: mappedContent.content.type, value: mappedContent.content.value },
							placeholders: mappedContent.placeholders,
						})
					} else {
						await onSave(null)
					}
					return true
				} catch (error) {
					console.error(error)
					await alert.show("Error", "Failed to save student email. Please try again.")
					return false
				}
			})
		} else {
			toast.clearUnsavedChanges()
		}
	}, [
		alert,
		contentWithPlaceholders,
		customContent,
		customHtmlContent,
		customTitle,
		email,
		emailType,
		mappedContent,
		selectedOptionId,
		onSave,
		toast,
	])

	const availablePlaceholderKeys = email
		? ["platformName", "primaryColor", ...email.placeholders.map(x => x.key)]
		: []

	return (
		<Content>
			<RadioButtons
				options={options
					.filter(x => email.default || x.id !== "default")
					.map(x => ({ value: x.id, label: x.label }))}
				value={selectedOptionId}
				onChange={value => setSelectedOptionId(options.find(x => x.id === value)?.id ?? "default")}
			/>
			{selectedOptionId === "custom" && (
				<>
					<p>
						Design your email using{" "}
						<a
							href="https://documentation.mjml.io/#getting-started"
							target="_blank"
							rel="noopener noreferrer"
						>
							MJML
						</a>
						. This will use your layout customized{" "}
						<Link to={routes.settings.studentEmail.layout()}>here</Link>.
					</p>
					<p>
						The available placeholders are{" "}
						{availablePlaceholderKeys.map((key, index) => (
							<Fragment key={key}>
								<PlaceholderKey>{`{${key}}`}</PlaceholderKey>
								{index < availablePlaceholderKeys.length - 1 ? ", " : ""}
							</Fragment>
						))}
					</p>
					<StyledInput label="Subject" value={customTitle} onChange={setCustomTitle} />
					<CodeEditor label="MJML content" defaultValue={customContent} onChange={setCustomContent} />
				</>
			)}
			{selectedOptionId === "custom-html" && (
				<>
					<p>
						Write email compliant HTML code to customize your email. This will not use your layout
						customized <Link to={routes.settings.studentEmail.layout()}>here</Link>. Please note that even
						if the email looks good in the preview it may not look identical when sent. This is because
						email clients have varying support for HTML. We recommend you design your email in MJML above
						instead.
					</p>
					<p>
						The available placeholders are{" "}
						{availablePlaceholderKeys.map((key, index) => (
							<Fragment key={key}>
								<PlaceholderKey>{`{${key}}`}</PlaceholderKey>
								{index < availablePlaceholderKeys.length - 1 ? ", " : ""}
							</Fragment>
						))}
					</p>
					<StyledInput label="Subject" value={customTitle} onChange={setCustomTitle} />
					<CodeEditor label="HTML content" defaultValue={customHtmlContent} onChange={setCustomHtmlContent} />
				</>
			)}
		</Content>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

const StyledInput = styled(InputField)`
	align-self: stretch;
`

const PlaceholderKey = styled.code`
	font-size: 0.8em;
`

export default EditStudentEmail
