import { type FC, useState } from "react"
import { useNavigate } from "react-router"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"
import { getDataUrlByFile } from "@forento/shared/utilities/file"

import { SubmitButton } from "~/components/Button"
import FileChooser from "~/components/FileChooser"
import ImageUploader from "~/components/ImageUploader"
import InputField, { useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import ProductPricing from "~/components/ProductPricing"
import RadioButtons from "~/components/RadioButtons"
import { useImageFile } from "~/hooks/useImageFile"
import usePrice from "~/hooks/usePrice"
import useProductPublishedAlert from "~/hooks/useProductPublishedAlert"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

const options = [
	{ id: "upload", label: "Upload file" },
	{ id: "link", label: "Link" },
] as const

const CreateDownloadablePage: FC = () => {
	const navigate = useNavigate()
	const publishedAlert = useProductPublishedAlert("downloadable")

	const [name, setName] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const [isSignInRequired, setSignInRequired] = useState(false)
	const { price, priceValue, setPrice } = usePrice()
	const [membershipIds, setMembershipIds] = useState<number[]>([])
	const [isPaused, setPaused] = useState(false)
	const [option, setOption] = useState<(typeof options)[number]["id"]>("upload")
	const [downloadable, setDownloadable] = useState<File | null>(null)
	const [link, setLink] = useState("")

	const [isSubmitting, setSubmitting] = useState(false)

	const thumbnail = useImageFile(-1, null)

	const handleSubmit = async () => {
		if (name.trim().length === 0) return

		try {
			if (option === "upload") {
				if (!downloadable) return
				setSubmitting(true)
				const created = await trpc.downloadable.create.mutate({
					name,
					shortDescription,
					longDescription: longDescription.exportEditorState(),
					isSignInRequired,
					price: priceValue,
					membershipIds,
					isPaused,
					thumbnailDataUrl: await thumbnail.exportToDataUrl(),
					type: "fileUpload",
					downloadableDataUrl: await getDataUrlByFile(downloadable),
				})
				navigate(routes.downloadable.index())
				await publishedAlert.show({ itemId: created.id })
			} else {
				if (link.trim().length === 0) return
				setSubmitting(true)
				const created = await trpc.downloadable.create.mutate({
					name,
					shortDescription,
					longDescription: longDescription.exportEditorState(),
					isSignInRequired,
					price: priceValue,
					membershipIds,
					isPaused,
					thumbnailDataUrl: await thumbnail.exportToDataUrl(),
					type: "link",
					downloadableUrl: link,
				})
				navigate(routes.downloadable.index())
				await publishedAlert.show({ itemId: created.id })
			}
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb path={[{ title: "Downloadables", link: routes.downloadable.index() }]} title="Create" />
			</PageHeader>
			<Content onSubmit={handleSubmit}>
				<Section>
					<Title>Basic information</Title>
					<InputField label="Name" value={name} onChange={setName} />
					<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
					{longDescription.element}
					<ImageUploader label="Thumbnail" {...thumbnail.imageUploader} />
				</Section>

				<Section>
					<Title>Pricing</Title>
					<ProductPricing
						type="downloadable"
						isSignInRequired={isSignInRequired}
						setSignInRequired={setSignInRequired}
						price={price}
						setPrice={setPrice}
						membershipIds={membershipIds}
						setMembershipIds={setMembershipIds}
						isPaused={isPaused}
						setPaused={setPaused}
					/>
				</Section>

				<Section>
					<Title>File</Title>
					<RadioButtons
						options={options.map(option => ({ value: option.id, label: option.label }))}
						value={option}
						onChange={value => setOption(options.find(x => x.id === value)?.id ?? "upload")}
					/>
					{option === "upload" && (
						<div>
							<InputLabel>Downloadable file</InputLabel>
							<FileChooser type="file" value={downloadable} onChange={setDownloadable} />
						</div>
					)}
					{option === "link" && (
						<div>
							<InputField
								label="Link"
								value={link}
								onChange={setLink}
								placeholder="https://link.to/your-file.pdf"
							/>
						</div>
					)}
				</Section>
				<StyledSubmitButton
					variant="primary"
					isDisabled={
						!(
							name.trim().length > 0 &&
							((option === "upload" && downloadable) || (option === "link" && link.trim().length > 0))
						)
					}
					isLoading={isSubmitting}
				>
					Create downloadable
				</StyledSubmitButton>
			</Content>
		</Layout>
	)
}

const Content = styled(Form)`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 24px;
	color: black;
`

const StyledSubmitButton = styled(SubmitButton)`
	align-self: flex-end;
`

export default CreateDownloadablePage
