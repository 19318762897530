import { type FC, useCallback, useState } from "react"
import { Navigate, useParams } from "react-router"

import EditStudentEmail, { type EmailContent, type EmailSaveData } from "~/components/EditStudentEmail"
import EmailPreview from "~/components/EmailPreview"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

const StudentEmailDetailSettingsPage: FC = () => {
	const { emailId } = useParams()

	const [previewContent, setPreviewContent] = useState<EmailContent | null>(null)

	const { data: layout, error: layoutError } = query.studentEmails.getLayout.useQuery()
	const { data: email, error: emailError, refetch } = query.studentEmails.get.useQuery(emailId ?? "")

	const handleSave = useCallback(
		async (data: EmailSaveData) => {
			if (email?.id === undefined) return
			await trpc.studentEmails.update.mutate({ emailId: email?.id, data: { custom: data } })
			await refetch()
		},
		[email?.id, refetch],
	)

	if (email === null) {
		return <Navigate to={routes.settings.studentEmail.index()} replace />
	}

	return (
		<Layout asideContent={previewContent && <EmailPreview {...previewContent} />}>
			<PageBreadcrumb
				title={email?.label ?? "..."}
				path={[
					{ title: "Settings", link: routes.settings.index() },
					{ title: "Student Emails", link: routes.settings.studentEmail.index() },
				]}
			/>
			{layoutError || emailError ? (
				<p>Failed to load student email.</p>
			) : !layout || !email ? (
				<PartialLoadingPage />
			) : (
				<EditStudentEmail
					email={email}
					layout={layout.content}
					onSave={handleSave}
					onContentWithPlaceholderChange={setPreviewContent}
				/>
			)}
		</Layout>
	)
}

export default StudentEmailDetailSettingsPage
