import { type FC, useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import Table from "~/components/Table"
import { useAlert } from "~/contexts/AlertContext"
import { dangerColor, primaryColor, successColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import SelectedStatus from "./SelectedStatus"

type Props = {
	domain: {
		id: number
		domain: string
		platform: { subdomain: string }
		isSelected: boolean
		records: { type: string; host: string; value: string; isConnected: boolean }[] | null
	}
	reload(): Promise<void>
}
const ManualDomain: FC<Props> = ({ domain, reload }) => {
	const alert = useAlert()

	const [isSelecting, setSelecting] = useState(false)
	const [isDeleting, setDeleting] = useState(false)
	const [isChecking, setChecking] = useState(false)
	const [recordsToRemove, setRecordsToRemove] = useState<{ type: "A" | "CNAME"; host: string; value: string }[]>([])

	async function handleSetSelected(selected: boolean) {
		setSelecting(true)
		try {
			await trpc.domain.setSelected.mutate({ domain: selected ? domain.domain : null })
			await reload()
		} catch (error) {
			console.error(`Failed to ${selected ? "select" : "deselect"} domain!`, error)
		} finally {
			setSelecting(false)
		}
	}

	async function handleChangeSubdomain() {
		const result = await alert.input(
			"Change subdomain",
			`Enter a new subdomain for the domain **${domain.domain}**`,
			"Subdomain",
			domain.platform.subdomain,
		)
		if (result.result === null) return

		await trpc.domain.updateManual.mutate({ id: domain.id, platformSubdomain: result.result.trim() })
		await reload()

		result.close()
	}

	async function handleDelete() {
		setDeleting(true)
		try {
			await trpc.domain.deleteManual.mutate({ domain: domain.domain })
			await reload()
		} catch (error) {
			console.error("Failed to delete domain!", error)
		} finally {
			setDeleting(false)
		}
	}

	async function handleCheck() {
		setChecking(true)
		try {
			const result = await trpc.domain.checkManual.mutate({ domain: domain.domain })
			setRecordsToRemove(result.remove)
			await reload()
		} catch (error) {
			console.error("Failed to check domain!", error)
		} finally {
			setChecking(false)
		}
	}

	return (
		<Container>
			<Header>
				<Details>
					<Title>{domain.domain}</Title>
					<SelectedStatus isSelected={domain.isSelected} />
				</Details>
				<ExpireDate />
				<AutoRenew />
				<Actions>
					<DangerButton onClick={handleDelete} isLoading={isDeleting}>
						Delete
					</DangerButton>
					<ChangeButton onClick={handleChangeSubdomain}>Change subdomain</ChangeButton>
					{domain.isSelected ? (
						<DangerButton onClick={() => handleSetSelected(false)} isLoading={isSelecting}>
							Disconnect
						</DangerButton>
					) : (
						<PublishButton onClick={() => handleSetSelected(true)} isLoading={isSelecting}>
							Publish
						</PublishButton>
					)}
				</Actions>
			</Header>
			{domain.records !== null && domain.records.some(x => !x.isConnected) && (
				<Connect>
					<ConnectDescription>
						<p>
							Please log in to your domain registrar's admin console (the website where you purchased the
							domain) and add the following DNS records.
						</p>
						<p>If prompted to set a TTL (Time To Live), use the default value or set it to 3600.</p>
					</ConnectDescription>
					<Table
						columns={["Type", "Name", "Value", "Connected"]}
						data={domain.records.map(({ type, host, value, isConnected }) => [
							type,
							host,
							value,
							<ConnectionStatus $isConnected={isConnected}>
								{isConnected ? "Yes" : "No"}
							</ConnectionStatus>,
						])}
					/>
					{recordsToRemove.length > 0 && (
						<>
							<p>
								The following DNS records conflict with the platform's DNS records and need to be
								removed.
							</p>
							<Table
								columns={["Type", "Name", "Value"]}
								data={recordsToRemove.map(({ type, host, value }) => [type, host, value])}
							/>
						</>
					)}
					<Button variant="primary" onClick={handleCheck} isLoading={isChecking}>
						Check connection
					</Button>
				</Connect>
			)}
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfbf9;
	border: 1px solid #eaeaea;
	border-radius: 12px;
	padding: 16px 22px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Details = styled.div`
	flex: 2 0 0;
`

const Title = styled.p`
	font-weight: 600;
	font-size: 16px;
`

const ExpireDate = styled.div`
	flex: 1 0 0;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: center;
`

const AutoRenew = styled.div`
	flex: 1 0 0;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: center;
`

const Actions = styled.div`
	flex: 0 0 320px;
	display: flex;
	justify-content: flex-end;
	gap: 16px;
`

const DangerButton = styled(Button)`
	font-weight: 600;
	font-size: 14px;
	color: ${dangerColor};
`

const ChangeButton = styled(Button)`
	font-weight: 600;
	font-size: 14px;
	color: ${primaryColor};
	white-space: nowrap;
`

const PublishButton = styled(Button)`
	font-weight: 600;
	font-size: 14px;
	color: ${primaryColor};
`

const Connect = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

const ConnectDescription = styled.div`
	font-size: 14px;
	display: flex;
	flex-direction: column;
	gap: 1em;
`

const ConnectionStatus = styled.p<{ $isConnected: boolean }>`
	color: ${props => (props.$isConnected ? successColor : dangerColor)};
`

export default ManualDomain
