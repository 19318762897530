import { type FC, useEffect, useState } from "react"
import styled, { css } from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import Collapse from "@forento/shared/components/Collapse"
import VideoPlayer from "@forento/shared/components/VideoPlayer"
import { type VideoCaptionLanguageCode } from "@forento/shared/models/video"

import Button from "~/components/Button"
import { ChevronIcon } from "~/components/Icon"
import ImageUploader from "~/components/ImageUploader"
import InputField, { useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import LoadingIndicator from "~/components/LoadingIndicator"
import VideoCaptions from "~/components/VideoCaptions"
import VideoUploader from "~/components/VideoUploader"
import { useVideoUploader } from "~/contexts/VideoUploadContext"
import { useImageFile } from "~/hooks/useImageFile"
import { arrayBufferToBase64 } from "~/utilities/buffer"
import { primaryColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import Attachments from "./Attachments"
import Links from "./Links"
import { ButtonsGroup, ButtonsRow, DeleteMediaButton, DeleteMediaIcon } from "./styles"

type Video = { host: "apiVideo"; apiVideoId: string } | { host: "bunny"; bunnyVideoId: string }

type Props = {
	page: {
		id: number
		title: string
		text: string | null
		video:
			| { host: "apiVideo"; apiVideoId: string }
			| { host: "bunny"; bunnyVideoId: string; thumbnailUrl: string | null; captions: VideoCaptionLanguageCode[] }
			| null
		attachments: { id: number; fileType: string; label: string; url: string }[]
		links: { id: number; label: string; url: string }[]
	}
	isSaving: boolean
	isDeleting: boolean
	onClose(): void
	onSavePage: (data: {
		title: string
		text: string | null
		thumbnailDataUrl?: string | null
		addCaptions: { language: VideoCaptionLanguageCode; base64: string }[]
		removeCaptions: VideoCaptionLanguageCode[]
	}) => void
	onDeletePage(): void
}
const EditVideoPageModalContent: FC<Props> = ({ page, isSaving, isDeleting, onClose, onSavePage, onDeletePage }) => {
	const videoUploader = useVideoUploader()

	const [title, setTitle] = useState(page.title)
	const text = useRichTextArea({ label: "Text", initialValue: page.text, features: { headings: true, colors: true } })
	const [video, setVideo] = useState<Video | null>(page.video)
	const [addCaptions, setAddCaptions] = useState<{ language: VideoCaptionLanguageCode; file: File }[]>([])
	const [removeCaptions, setRemoveCaptions] = useState<VideoCaptionLanguageCode[]>([])
	const [isDeletingVideo, setDeletingVideo] = useState(false)

	const [showAdvanced, setShowAdvanced] = useState(false)

	const thumbnail = useImageFile(page.id, page.video?.host === "bunny" ? page.video.thumbnailUrl : null)

	const uploadingVideo = videoUploader.getByPageId(page.id)

	useEffect(() => {
		const abortController = new AbortController()

		setTitle(page.title)
		setVideo(page.video)
		setAddCaptions([])
		setRemoveCaptions([])

		return () => {
			abortController.abort()
		}
	}, [page])

	useEffect(() => {
		if (uploadingVideo?.status === "completed") {
			setVideo({ host: "bunny", bunnyVideoId: uploadingVideo.bunnyVideoId })
		} else if (uploadingVideo?.status === "failed") {
			setVideo(null)
		}
	}, [uploadingVideo?.bunnyVideoId, uploadingVideo?.status])

	async function handleDownloadVideo() {
		try {
			const url = await trpc.course.getVideoDownloadUrlByPageId.query(page.id)
			window.open(url, "_blank")
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<>
			<InputField label="Title" value={title} onChange={setTitle} />
			{text.element}
			<div>
				<InputLabel>Video</InputLabel>
				<AspectRatio width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9}>
					{video !== null ? (
						<PlayerContainer>
							<VideoPlayer video={video} />
							<DeleteMediaButton
								onClick={async () => {
									if (isDeletingVideo) return
									setDeletingVideo(true)
									try {
										await trpc.course.deletePageVideo.mutate(page.id)
										setVideo(null)
									} finally {
										setDeletingVideo(false)
									}
								}}
							>
								{!isDeletingVideo ? (
									<DeleteMediaIcon />
								) : (
									<DeleteMediaIcon as={LoadingIndicator} color="white" />
								)}
							</DeleteMediaButton>
						</PlayerContainer>
					) : (
						<VideoUploader
							onUpload={async file => {
								await videoUploader.start({
									file,
									pageId: page.id,
									title: title.length > 0 ? title : "Untitled page",
								})
							}}
							progress={uploadingVideo?.status === "uploading" ? uploadingVideo.progress : null}
						/>
					)}
				</AspectRatio>
			</div>
			{video?.host === "bunny" && (
				<AdvancedSettings>
					<AdvancedSettingsButton onClick={() => setShowAdvanced(current => !current)}>
						<AdvancedSettingsButtonIcon $isOpen={showAdvanced} />
						{showAdvanced ? "Hide" : "Show"} advanced video settings
					</AdvancedSettingsButton>
					<Collapse isCollapsed={!showAdvanced}>
						<AdvancedSettingsContent>
							<ImageUploader label="Custom thumbnail" {...thumbnail.imageUploader} />
							<div>
								<InputLabel>Captions</InputLabel>
								<VideoCaptions
									captions={page.video?.host !== "apiVideo" ? (page.video?.captions ?? []) : []}
									addCaptions={addCaptions}
									removeCaptions={removeCaptions}
									setAddCaptions={setAddCaptions}
									setRemoveCaptions={setRemoveCaptions}
								/>
							</div>
							<Button variant="secondary" onClick={handleDownloadVideo}>
								Download video
							</Button>
						</AdvancedSettingsContent>
					</Collapse>
				</AdvancedSettings>
			)}
			<Attachments pageId={page.id} attachments={page.attachments} />
			<Links pageId={page.id} links={page.links} />
			<ButtonsRow>
				<ButtonsGroup>
					<Button variant="secondary" onClick={onClose} isDisabled={isSaving || isDeleting}>
						Cancel
					</Button>
					<Button
						variant="primary"
						isDisabled={title.trim().length === 0 || isDeleting}
						isLoading={isSaving}
						onClick={async () => {
							onSavePage({
								title,
								text: text.exportEditorState(),
								thumbnailDataUrl:
									video?.host === "bunny" ? await thumbnail.exportToDataUrl() : undefined,
								addCaptions: await Promise.all(
									addCaptions.map(async x => ({
										language: x.language,
										base64: arrayBufferToBase64(await x.file.arrayBuffer()),
									})),
								),
								removeCaptions,
							})
						}}
					>
						Save
					</Button>
				</ButtonsGroup>
				<Button variant="primary-danger" onClick={onDeletePage} isDisabled={isSaving} isLoading={isDeleting}>
					Delete
				</Button>
			</ButtonsRow>
		</>
	)
}

const PlayerContainer = styled.div`
	position: relative;
`

const AdvancedSettings = styled.div`
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 8px;
	font-weight: 500;
	font-size: 16px;
`

const AdvancedSettingsButton = styled(Button)`
	display: flex;
	align-items: center;
	gap: 4px;
	font-weight: 500;
	font-size: 16px;
`

const AdvancedSettingsButtonIcon = styled(ChevronIcon)<{ $isOpen: boolean }>`
	width: 24px;
	height: 24px;
	transition: transform 0.15s;

	${props =>
		props.$isOpen &&
		css`
			transform: rotate(90deg);
		`}
`

const AdvancedSettingsContent = styled.div`
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default EditVideoPageModalContent
