import { useEffect, useMemo, useState } from "react"

import { getDataUrlByFile, getFileNameFromPath } from "@forento/shared/utilities/file"

export type ImageFile = {
	filePath: string | null
	isModified: boolean
	imageUploader: { filePath: string | null; onUpload(file: File): void; onDelete(): void }
	exportToDataUrl(): Promise<string | null>
	clear(): void
}
export function useImageFile(productId: number, initialFilePath: string | null): ImageFile {
	const [file, setFile] = useState<File | null>(null)
	const [filePath, setFilePath] = useState<string | null>(null)
	const [isEverChanged, setEverChanged] = useState(false)

	useEffect(() => {
		const abortController = new AbortController()

		setEverChanged(false)
		if (initialFilePath) {
			fetch(initialFilePath, { signal: abortController.signal })
				.then(async response => {
					if (!response.ok) throw new Error("Failed to fetch image")
					return {
						name: getFileNameFromPath(response.url),
						blob: await response.blob(),
					}
				})
				.then(({ name, blob }) => {
					if (abortController.signal.aborted) return
					setFile(new File([blob], name ?? "Image", { type: blob.type }))
				})
				// The image was not found. Probably because of thumbnail of unprocessed video.
				.catch(() => setFile(null))
		} else {
			setFile(null)
		}

		return () => {
			abortController.abort()
		}
	}, [initialFilePath, productId])

	useEffect(() => {
		if (file === null) {
			setFilePath(null)
			return
		}

		const url = URL.createObjectURL(file)
		setFilePath(url)

		return () => {
			URL.revokeObjectURL(url)
		}
	}, [file])

	const value = useMemo<ImageFile>(
		() => ({
			filePath,
			isModified: isEverChanged && !(initialFilePath === null && file === null),
			imageUploader: {
				filePath,
				onUpload(file: File): void {
					setFile(file)
					setEverChanged(true)
				},
				onDelete(): void {
					setFile(null)
					setEverChanged(true)
				},
			},
			async exportToDataUrl(): Promise<string | null> {
				if (file === null) return null
				return await getDataUrlByFile(file)
			},
			clear(): void {
				setFile(null)
				setFilePath(null)
				setEverChanged(true)
			},
		}),
		[file, filePath, isEverChanged, initialFilePath],
	)

	return value
}
