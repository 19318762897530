import { type FC } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { CheckmarkIcon, OpenIcon } from "~/components/Icon"

import { type Task } from "."

const UpcomingTask: FC<{ task: Task }> = ({ task }) => (
	<Container>
		<Icon>{task.icon}</Icon>
		<Title>{task.title}</Title>
		<HelpLink onClick={`https://forento.link/${task.helpLink.dubSlug}`} newTab>
			{task.helpLink.label}
		</HelpLink>
		<CallToAction onClick={task.onClick}>
			<CallToActionIcon as={OpenIcon} />
		</CallToAction>
	</Container>
)

const Container = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 16px;
	display: grid;
	align-items: center;
	grid-template-columns: max-content 1fr max-content;
	grid-template-rows: max-content max-content;
	grid-template-areas:
		"icon title callToAction"
		"icon help callToAction";
	gap: 4px 8px;
`

const Icon = styled.div`
	grid-area: icon;
	width: 40px;
	height: 40px;
	padding: 8px;
`

const Title = styled.p`
	grid-area: title;
	font-weight: 600;
	font-size: 16px;
`

const HelpLink = styled(Button)`
	grid-area: help;
	color: #777777;
	font-size: 16px;
	font-weight: 500;
	text-decoration: underline;
`

const CallToAction = styled(Button)`
	grid-area: callToAction;
	width: 40px;
	height: 40px;
	font-weight: 600;
	padding: 8px;
	color: black;
	background: #efefef;
	border-radius: 50%;
`

const CallToActionIcon = styled(CheckmarkIcon)`
	width: 100%;
	height: 100%;
`

export default UpcomingTask
