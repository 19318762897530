export type PayPayOnboardingError = "unconfirmed-email" | "not-onboarded"

export type PayPayOnboardingStatus = "success" | PayPayOnboardingError

export type PaymentStatus = "succeeded" | "pending" | "failed"

export type PayoutStatus = "paid" | "pending" | "in_transit" | "canceled" | "failed"

export const checkoutTypes = [
	"coursePurchase",
	"videoPurchase",
	"downloadablePurchase",
	"bundlePurchase",
	"sessionPurchase",
	"eventPurchase",
] as const
export type CheckoutType = (typeof checkoutTypes)[number]

export const subscriptionTypes = ["plan", "addon", "membership", "domain"] as const
export type SubscriptionType = (typeof subscriptionTypes)[number]

export type PaymentType = "course" | "video" | "downloadable" | "bundle" | "session" | "event"

export interface Payment {
	id: string
	currency: string
	amount: {
		grossTotal: number
		applicationFee: number
		netTotal: number
	}
	status: PaymentStatus
	date: Date
}

export interface Payout {
	id: string
	currency: string
	amount: number
	status: PayoutStatus
	date: Date
	arrivalDate: Date
}

export interface Transaction {
	id: string
	currency: string
	amount: number
	status: "success" | "pending" | "failed"
	receipt: { status: "available"; url: string } | { status: "paypal" } | { status: "unavailable" }
	date: Date
	item:
		| { type: "course"; title: string }
		| { type: "video"; title: string }
		| { type: "downloadable"; title: string }
		| { type: "event"; title: string }
		| null
}

export interface StripePaymentInformation {
	balances: {
		currency: string
		amount: number
	}[]
	payments: Payment[]
	payouts: Payout[]
}

export interface PayPalPaymentInformation {
	balances: {
		currency: string
		amount: number
	}[]
	payments: Payment[]
}

export type PaymentInformation =
	| ({ type: "stripe" } & StripePaymentInformation)
	| ({ type: "paypal" } & PayPalPaymentInformation)

export const subscriptionPaymentIntervals = ["month", "year"] as const
export type SubscriptionPaymentInterval = (typeof subscriptionPaymentIntervals)[number]

export interface SubscriptionPlan {
	id: number | null
	price: {
		amount: number
		interval: SubscriptionPaymentInterval
	} | null
}

export const cardBrands = ["amex", "diners", "discover", "jcb", "mastercard", "unionpay", "visa"] as const
export type CardBrand = (typeof cardBrands)[number]

export interface PaymentMethod {
	id: string
	brand: CardBrand | null
	last4: string
	expiration: {
		month: number
		year: number
	}
	wallet:
		| "amex_express_checkout"
		| "apple_pay"
		| "google_pay"
		| "masterpass"
		| "samsung_pay"
		| "visa_checkout"
		| "link"
		| null
}

export type PlanAccess =
	| "customDomain"
	| "landingPage"
	| "videos"
	| "bundles"
	| "community"
	| "assignProducts"
	| "zapier"
	| "chapterDripping"
	| "certificates"
	| "communityChannels"
	| "noWatermark"

export interface Plan {
	id: number | null
	title: string
	description: string
	usps: string[]
	prices: {
		current: {
			month: { id: string; amount: number }
			year: { id: string; amount: number }
		}
		platform: {
			month: { id: string; amount: number }
			year: { id: string; amount: number }
		} | null
	} | null
	commission: number
	access: {
		[key in PlanAccess]: boolean
	}
}

export type AddonDetails = {
	title: string
	description: string
	usps: string[]
	prices: {
		month: { id: string; amount: number }
		year: { id: string; amount: number }
	}
}

export interface PayPalOrder {
	id: string
	amount: number
	currency: string
	platformFee: number
	status: "success" | "pending"
	customerEmail: string
	date: Date
}

export interface DiscountCode {
	id: string
	code: string
	name: string
	maxRedemptions: number | null
	lastRedepmptionDate: Date | null
	discount: {
		type: "amount" | "percent"
		value: number
	}
	duration: { type: "repeating"; months: number } | { type: "once" | "forever" }
}

export const cancelReasonIds = ["switched", "features", "ltd", "expectations", "price", "other"] as const

export type CancelReasonId = (typeof cancelReasonIds)[number]

export const addonTypes = ["noCommission", "scale"] as const
export type AddonType = (typeof addonTypes)[number]
