import { type FC, useEffect, useState } from "react"

import Button from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import ImageUploader from "~/components/ImageUploader"
import InputField, { useRichTextArea } from "~/components/InputField"
import { useImageFile } from "~/hooks/useImageFile"

import Attachments from "./Attachments"
import Links from "./Links"
import { ButtonsGroup, ButtonsRow } from "./styles"

type Props = {
	page: {
		id: number
		title: string
		text: string | null
		image: { filePath: string; contain: boolean } | null
		attachments: { id: number; fileType: string; label: string; url: string }[]
		links: { id: number; label: string; url: string }[]
	}
	isSaving: boolean
	isDeleting: boolean
	onClose(): void
	onSavePage(data: {
		title: string
		text: string | null
		imageDataUrl: string | null | undefined
		containImage: boolean
	}): void
	onDeletePage(): void
}

const EditTextPageModalContent: FC<Props> = ({ page, isSaving, isDeleting, onClose, onSavePage, onDeletePage }) => {
	const [title, setTitle] = useState(page.title)
	const text = useRichTextArea({ label: "Text", initialValue: page.text, features: { headings: true, colors: true } })
	const image = useImageFile(page.id, page.image?.filePath ?? null)
	const [containImage, setContainImage] = useState(page.image?.contain ?? false)

	useEffect(() => {
		setTitle(page.title)
		setContainImage(page.image?.contain ?? false)
	}, [page])

	return (
		<>
			<InputField label="Title" value={title} onChange={setTitle} />
			{text.element}
			<ImageUploader {...image.imageUploader} />
			{image.filePath !== null && (
				<Dropdown
					items={[
						{ id: "cover", title: "Show as banner" },
						{ id: "contain", title: "Show full image" },
					]}
					selectedItemId={containImage ? "contain" : "cover"}
					onChange={id => setContainImage(id === "contain")}
				/>
			)}
			<Attachments pageId={page.id} attachments={page.attachments} />
			<Links pageId={page.id} links={page.links} />
			<ButtonsRow>
				<ButtonsGroup>
					<Button variant="secondary" onClick={onClose} isDisabled={isSaving || isDeleting}>
						Cancel
					</Button>
					<Button
						variant="primary"
						isDisabled={title.trim().length === 0 || isDeleting}
						isLoading={isSaving}
						onClick={async () =>
							onSavePage({
								title,
								text: text.exportEditorState(),
								imageDataUrl: image.isModified ? await image.exportToDataUrl() : undefined,
								containImage,
							})
						}
					>
						Save
					</Button>
				</ButtonsGroup>
				<Button variant="primary-danger" onClick={onDeletePage} isDisabled={isSaving} isLoading={isDeleting}>
					Delete
				</Button>
			</ButtonsRow>
		</>
	)
}

export default EditTextPageModalContent
