import { type FC, useState, useLayoutEffect, type Dispatch, type SetStateAction } from "react"
import styled from "styled-components"

import { type Course } from "@forento/shared/models/course"
import { type Downloadable } from "@forento/shared/models/downloadable"
import { type Membership } from "@forento/shared/models/membership"
import { type ThinPrivateSession } from "@forento/shared/models/privateSession"
import { type Video } from "@forento/shared/models/video"
import { toggleArrayItem } from "@forento/shared/utilities/array"

import Button, { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import ImageUploader from "~/components/ImageUploader"
import InputField, { useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/UserContext"
import { type ImageFile, useImageFile } from "~/hooks/useImageFile"
import trpc, { query } from "~/utilities/trpc"

type Data = {
	title: string
	shortDescription: string
	courseIds: number[]
	videoIds: number[]
	downloadableIds: number[]
	monthlyPricing: { isEnabled: boolean; value: number }
	annuallyPricing: { isEnabled: boolean; value: number }
}

type Props = { membershipId: number | null; isOpen: boolean; onClose(): void; onSubmitted(): Promise<unknown> }
const EditModal: FC<Props> = ({ membershipId, isOpen, onClose, onSubmitted }) => {
	const alert = useAlert()

	const enabled = isOpen && membershipId !== null
	const { data: membership, error, refetch } = query.membership.get.useQuery(membershipId ?? -1, { enabled })

	const courses = query.course.list.useQuery(undefined, { enabled })
	const videos = query.video.list.useQuery(undefined, { enabled })
	const downloadables = query.downloadable.list.useQuery(undefined, { enabled })
	const sessions = query.privateSession.list.useQuery(undefined, { enabled })

	const [data, setData] = useState<Data>({
		title: "",
		shortDescription: "",
		courseIds: [],
		videoIds: [],
		downloadableIds: [],
		monthlyPricing: { isEnabled: false, value: 0 },
		annuallyPricing: { isEnabled: false, value: 0 },
	})
	const longDescription = useRichTextArea({ label: "Description", initialValue: membership?.longDescription })
	const [isSubmitting, setSubmitting] = useState(false)

	const thumbnail = useImageFile(membership?.id ?? -1, membership?.thumbnailFilePath ?? null)

	useLayoutEffect(() => {
		setData({
			title: membership?.title ?? "",
			shortDescription: membership?.shortDescription ?? "",
			courseIds: membership?.content.filter(x => x.type === "course").map(x => x.id) ?? [],
			videoIds: membership?.content.filter(x => x.type === "video").map(x => x.id) ?? [],
			downloadableIds: membership?.content.filter(x => x.type === "downloadable").map(x => x.id) ?? [],
			monthlyPricing: {
				isEnabled: membership?.prices.find(x => x.paymentInterval === "month") !== undefined,
				value: membership?.prices.find(x => x.paymentInterval === "month")?.amount ?? 0,
			},
			annuallyPricing: {
				isEnabled: membership?.prices.find(x => x.paymentInterval === "year") !== undefined,
				value: membership?.prices.find(x => x.paymentInterval === "year")?.amount ?? 0,
			},
		})
	}, [membership])

	async function handleSubmit() {
		if (!membership) return

		if (data.title.length === 0) {
			alert.show("Error", "Please fill in the title.")
			return
		}
		if (!data.monthlyPricing.isEnabled && !data.annuallyPricing.isEnabled) {
			alert.show("Error", "You need at least one pricing plan.")
			return
		}
		if (
			(data.monthlyPricing.isEnabled && data.monthlyPricing.value <= 0) ||
			(data.annuallyPricing.isEnabled && data.annuallyPricing.value <= 0)
		) {
			alert.show(
				"Free membership",
				'You don\'t need to create any free membership tier. If you want to offer free content to users of your platform, you can publish products as "Free" or "Signed in". This will allow your users to access content without going through any sort of payment gateway checkout. You can learn more here: [🛒 Products on Forento](https://forento.link/membership-modal-products) & [📦 Memberships](https://forento.link/membership-modal-memberships).',
			)
			return
		}
		if (
			data.monthlyPricing.isEnabled &&
			data.annuallyPricing.isEnabled &&
			data.annuallyPricing.value > data.monthlyPricing.value * 12
		) {
			const response = confirm(
				"Your annual plan is more expensive than your monthly plan. Are you sure this is intentional?",
			)
			if (response === false) return
		}

		if (membership.prices.some(x => x.paymentInterval === "month") && !data.monthlyPricing.isEnabled) {
			const dialog = await alert.confirm(
				"Delete monthly plan",
				"You have deleted the monthly plan. If this has current subscribers they will be immediately cancelled. Are you sure you want to delete it?",
			)
			if (!dialog.result) return
			dialog.close()
		}
		if (membership.prices.some(x => x.paymentInterval === "year") && !data.annuallyPricing.isEnabled) {
			const dialog = await alert.confirm(
				"Delete annual plan",
				"You have deleted the annual plan. If this has current subscribers they will be immediately cancelled. Are you sure you want to delete it?",
			)
			if (!dialog.result) return
			dialog.close()
		}

		setSubmitting(true)

		try {
			await trpc.membership.update.mutate({
				id: membership.id,
				data: {
					title: data.title,
					shortDescription: data.shortDescription,
					longDescription: longDescription.exportEditorState(),
					thumbnailDataUrl: await thumbnail.exportToDataUrl(),
					content: { courses: data.courseIds, videos: data.videoIds, downloadables: data.downloadableIds },
					price: {
						month: data.monthlyPricing.isEnabled ? data.monthlyPricing.value : null,
						year: data.annuallyPricing.isEnabled ? data.annuallyPricing.value : null,
					},
				},
			})

			await Promise.all([refetch(), onSubmitted()])
			onClose()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			{error || courses.error || videos.error || downloadables.error || sessions.error ? (
				<p>Failed to load membership.</p>
			) : !membership || !courses.data || !videos.data || !downloadables.data || !sessions.data ? (
				<PartialLoadingPage />
			) : (
				<Content
					membership={membership}
					data={data}
					setData={setData}
					longDescription={longDescription}
					thumbnail={thumbnail}
					paidContent={{
						courses: courses.data.filter(x => x.price),
						videos: videos.data.filter(x => x.price),
						downloadables: downloadables.data.filter(x => x.price),
						sessions: sessions.data.filter(x => x.price),
					}}
					isSubmitting={isSubmitting}
					onClose={onClose}
					onSubmitted={onSubmitted}
				/>
			)}
		</DefaultModal>
	)
}

export default EditModal

type ContentProps = {
	membership: Membership
	data: Data
	setData: Dispatch<SetStateAction<Data>>
	longDescription: ReturnType<typeof useRichTextArea>
	thumbnail: ImageFile
	paidContent: {
		courses: Course[]
		videos: Video[]
		downloadables: Downloadable[]
		sessions: ThinPrivateSession[]
	}
	isSubmitting: boolean
	onClose(): void
	onSubmitted(): Promise<unknown>
}
const Content: FC<ContentProps> = ({
	membership,
	data,
	setData,
	longDescription,
	thumbnail,
	paidContent,
	isSubmitting,
	onClose,
	onSubmitted,
}) => {
	const platform = usePlatform()!
	const alert = useAlert()

	function setField<T>(field: keyof Data): (value: T) => void {
		return value => setData(current => ({ ...current, [field]: value }))
	}

	async function handleDelete() {
		const dialog = await alert.confirm(
			"Delete membership tier",
			"Are you sure you want to delete this membership tier?",
		)
		if (!dialog.result) return

		const response = await trpc.membership.delete.mutate(membership.id)
		switch (response.status) {
			case "has-subscribers":
				return alert.show(
					"Failed to delete membership",
					"Could not delete membership tier because it has active subscribers.",
				)
		}

		await onSubmitted()
		dialog.close()
		onClose()
	}

	return (
		<>
			<ModalTitle>
				Edit tier <strong>{membership.title}</strong>
			</ModalTitle>
			<ModalInputs>
				<InputField label="Name" value={data.title} onChange={setField("title")} />
				<InputField
					label="Short description"
					value={data.shortDescription}
					onChange={setField("shortDescription")}
				/>
				{longDescription.element}
				<ImageUploader label="Thumbnail" {...thumbnail.imageUploader} />
				{(["course", "video", "downloadable"] as const).map(type => {
					const content = paidContent[`${type}s`]
					if (content.length === 0) return null

					const selectedIds = data[`${type}Ids`]
					return (
						<div key={type}>
							<InputLabel>Included {type}s</InputLabel>
							{content.map(item => (
								<StyledCheckbox
									key={item.id}
									label={item.title}
									isChecked={selectedIds.includes(item.id)}
									onChange={() => setField(`${type}Ids`)(toggleArrayItem(selectedIds, item.id))}
								/>
							))}
						</div>
					)
				})}
				<div>
					<InputLabel>Pricing</InputLabel>
					<Plans>
						<Plan>
							<StyledCheckbox
								label="Offer monthly plan"
								isChecked={data.monthlyPricing.isEnabled}
								onChange={value =>
									setField("monthlyPricing")({ ...data.monthlyPricing, isEnabled: value })
								}
							/>
							<InputField
								label={`Price per month (${platform.currency})`}
								inputType="number"
								value={data.monthlyPricing.value.toString()}
								onChange={value =>
									setField("monthlyPricing")({ ...data.monthlyPricing, value: Number(value) })
								}
								disabled={!data.monthlyPricing.isEnabled}
							/>
						</Plan>
						<Plan>
							<StyledCheckbox
								label="Offer annual plan"
								isChecked={data.annuallyPricing.isEnabled}
								onChange={value =>
									setField("annuallyPricing")({ ...data.annuallyPricing, isEnabled: value })
								}
							/>
							<InputField
								label={`Price per year (${platform.currency})`}
								inputType="number"
								value={data.annuallyPricing.value.toString()}
								onChange={value =>
									setField("annuallyPricing")({ ...data.annuallyPricing, value: Number(value) })
								}
								disabled={!data.annuallyPricing.isEnabled}
							/>
						</Plan>
					</Plans>
				</div>
			</ModalInputs>
			<ModalButtons>
				<Button variant="primary-danger" onClick={handleDelete}>
					Delete
				</Button>
				<Button variant="secondary" onClick={onClose}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isLoading={isSubmitting}>
					Update tier
				</SubmitButton>
			</ModalButtons>
		</>
	)
}

const Plans = styled.div`
	display: flex;
	gap: 16px;
`

const Plan = styled.div`
	flex: 1 0 0;
	min-width: 0;
`

const StyledCheckbox = styled(Checkbox)`
	margin-right: 8px;
	margin-bottom: 8px;
`
