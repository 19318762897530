import { IconGift, IconX } from "@tabler/icons-react"
import { useState, type FC } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import { useToast } from "~/contexts/ToastContext"
import { usePlatform, useUser } from "~/contexts/UserContext"
import { cardColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

const FreeTrial: FC = () => {
	const user = useUser()
	const platform = usePlatform()!
	const toast = useToast()

	const [isDismissOpen, setDismissOpen] = useState(false)
	const [trialDismissReason, setTrialDismissReason] = useState("")

	const startTrial = query.payment.creator.startAddonTrial.useMutation({
		async onSuccess() {
			await user.reload()
			toast.showSuccess("Trial started! You now have access to all features of the Scale addon for 7 days.")
		},
		onError(error) {
			console.error(error)
			toast.showError("Failed to start trial! Please try again later.")
		},
	})
	const dismissTrial = query.payment.creator.dismissAddonTrial.useMutation({
		async onSuccess() {
			await user.reload()
		},
		onError(error) {
			console.error(error)
			toast.showError("Failed to dismiss trial! Please try again later.")
		},
	})

	if (platform.trialEndDate !== null || platform.isTrialDismissed) return null
	if (platform.plan.type !== "appsumo" && platform.plan.type !== "viededingue") return null
	if (platform.plan.addon !== null) return null

	return (
		<Container>
			<DefaultModal isOpen={isDismissOpen}>
				<ModalTitle>Help us improve! 👋</ModalTitle>
				<ModalText>
					We noticed you didn't want to claim the free Scale trial, and that's completely fine! We're always
					looking to improve, so we'd love to know what made you decide not to try it. Was something missing,
					or did something hold you back? Your feedback will help us make Forento even better for creators
					like you. If you changed your mind, you can activate your trial now.
				</ModalText>
				<ModalInputs>
					<InputField
						label="Why did you pass on the free trial?"
						value={trialDismissReason}
						onChange={setTrialDismissReason}
					/>
				</ModalInputs>
				<ModalButtons>
					<Button
						variant="secondary"
						onClick={() => setDismissOpen(false)}
						isDisabled={startTrial.isPending || dismissTrial.isPending}
					>
						Cancel
					</Button>
					<Button
						variant="secondary"
						onClick={() => startTrial.mutate()}
						isDisabled={dismissTrial.isPending}
						isLoading={startTrial.isPending}
					>
						Activate trial
					</Button>
					<Button
						variant="primary-danger"
						onClick={() => dismissTrial.mutate(trialDismissReason)}
						isDisabled={startTrial.isPending || trialDismissReason.trim().length === 0}
						isLoading={dismissTrial.isPending}
					>
						Dismiss trial
					</Button>
				</ModalButtons>
			</DefaultModal>
			<Icon />
			<Title>Unlock everything for free!</Title>
			<Description>
				Try the Scale addon free for 7 days and access every feature Forento has to offer.
				<br />
				No credit card required. The plan will automatically cancel unless you decide to subscribe.
			</Description>
			<ClaimButton variant="primary" onClick={() => startTrial.mutate()} isLoading={startTrial.isPending}>
				Claim trial
			</ClaimButton>
			<DismissButton onClick={() => setDismissOpen(true)}>
				<IconX />
			</DismissButton>
		</Container>
	)
}

const Container = styled.div`
	background-color: ${cardColor.purple};
	border-radius: 24px;
	padding: 24px;
	display: grid;
	grid-template-areas:
		"icon title claim dismiss"
		"icon description claim dismiss";
	grid-template-columns: max-content 1fr max-content max-content;
	grid-template-rows: max-content max-content;
	align-items: center;
	gap: 4px 16px;
`

const Icon = styled(IconGift)`
	grid-area: icon;
`

const Title = styled.div`
	grid-area: title;
	font-size: 20px;
	font-weight: 600;
	line-height: 1;
`

const Description = styled.div`
	grid-area: description;
`

const ClaimButton = styled(Button)`
	grid-area: claim;
`

const DismissButton = styled(Button)`
	grid-area: dismiss;
`

export default FreeTrial
