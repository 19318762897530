import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { toCountFormat } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const Leaderboard: React.FC = () => {
	const { data: profiles, error } = query.community.listLeaderboard.useQuery()

	return (
		<>
			{error ? (
				<p>Failed to load leaderboard.</p>
			) : profiles === undefined ? (
				<PartialLoadingPage />
			) : (
				<Table
					isCompact
					columns={[{ label: "#", flex: { basis: 20, grow: 0 } }, "Name", "Level", "Total points"]}
					data={profiles.map((profile, index) => [
						<Cell key="rank">{index + 1}</Cell>,
						<Cell key="name" as={Button} onClick={routes.community.profile(profile.id)}>
							<CommunityProfileAvatar profile={profile} />
							<ProfileName>
								{profile.firstName} {profile.lastName}
							</ProfileName>
						</Cell>,
						<Cell key="level">{profile.gamification?.currentLevel?.label ?? "None"}</Cell>,
						<Cell key="points">{toCountFormat(profile.gamification?.points ?? 0)}</Cell>,
					])}
				/>
			)}
		</>
	)
}

const Cell = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	gap: 12px;
`

const ProfileName = styled.div`
	font-weight: 600;
	font-size: 14px;
`

export default Leaderboard
