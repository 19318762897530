import mime from "mime"
import { useEffect, useState, type FC } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { supportedFileExtensions } from "@forento/shared/utilities/file"

import { DeleteIcon, ImageIcon, SubtitlesIcon, UploadFileIcon, VideoFileIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import { primaryColor } from "~/utilities/styles"

import Button from "./Button"

const acceptByType = {
	image: Object.fromEntries(supportedFileExtensions.image.map(extension => [mime.getType(extension), []])),
	video: { "video/*": [] },
	"video-captions": { ".vtt": [], ".srt": [] },
	spreadsheet: Object.fromEntries(
		supportedFileExtensions.spreadsheet.map(extension => [mime.getType(extension), []]),
	),
	file: undefined,
} as const

const textByType = {
	image: "Click here or drag and drop to upload an image",
	video: "Click here or drag and drop to upload a video",
	"video-captions": "Click here or drag and drop to upload captions",
	spreadsheet: "Click here or drag and drop to upload a spreadsheet",
	file: "Click here or drag and drop to upload",
} as const

const iconByType = {
	image: ImageIcon,
	video: VideoFileIcon,
	"video-captions": SubtitlesIcon,
	spreadsheet: UploadFileIcon,
	file: UploadFileIcon,
} as const

type Type = "image" | "video" | "video-captions" | "spreadsheet" | "file"
type Props = { type: Type; value: File | null; onChange(value: File | null): void }
const FileChooser: FC<Props> = ({ type, value, onChange }) => {
	const alert = useAlert()

	const [uploadedFilePath, setUploadedFilePath] = useState<string | null>(null)

	const dropzone = useDropzone({
		accept: acceptByType[type],
		async onDrop(acceptedFiles: File[]) {
			const file = acceptedFiles[0]
			if (file === undefined) return

			if (file.size > 400 * 1024 * 1024) {
				await alert.show(
					"File too large",
					"The file is too large. It can be a maximum of 400 MB. Please upload a smaller file.",
				)
				return
			}

			onChange(file)
		},
	})

	useEffect(() => {
		if (value === null || !value.type.startsWith("image/")) {
			setUploadedFilePath(null)
			return
		}

		const url = URL.createObjectURL(value)
		setUploadedFilePath(url)

		return () => {
			URL.revokeObjectURL(url)
		}
	}, [value])

	return (
		<Dropzone {...dropzone.getRootProps()}>
			<input {...dropzone.getInputProps()} />
			{dropzone.isDragActive ? (
				<InfoText>
					{dropzone.isDragReject
						? "That file type is not supported"
						: type === "file"
							? "Drop file here"
							: `Drop ${type} file here`}
				</InfoText>
			) : value !== null ? (
				<>
					<DeleteButton onClick={() => onChange(null)}>
						<DeleteButtonIcon />
					</DeleteButton>
					{uploadedFilePath ? (
						<AspectRatio aspectRatio={16 / 9} width={{ value: 100, unit: "percent" }}>
							<ImagePreview alt="Uploaded image" src={uploadedFilePath} />
						</AspectRatio>
					) : (
						<p>{value.name}</p>
					)}
				</>
			) : (
				<>
					<Icon as={iconByType[type]} />
					<InfoText>{textByType[type]}</InfoText>
				</>
			)}
		</Dropzone>
	)
}

const Dropzone = styled.div`
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 32px;
	cursor: pointer;
	position: relative;
`

const Icon = styled.div`
	width: 64px;
	height: 64px;
	color: ${primaryColor};
`

const InfoText = styled.p`
	font-weight: 500;
	font-size: 16px;
	text-align: center;
`

const ImagePreview = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`

const DeleteButton = styled(Button)`
	position: absolute;
	top: 16px;
	left: 16px;
	width: 40px;
	height: 40px;
	padding: 8px;
	background-color: black;
	border-radius: 50%;
	color: white;
`

const DeleteButtonIcon = styled(DeleteIcon)`
	width: 100%;
	height: 100%;
`

export default FileChooser
