import { type FC, useState } from "react"

import InputLabel from "../InputLabel"
import Dropzone from "./Dropzone"
import Preview from "./Preview"
import Uploading from "./Uploading"

type Props = {
	label?: string
	filePath: string | null
	onUpload(file: File): Promise<void> | void
	onDelete(): Promise<void> | void
}
const ImageUploader: FC<Props> = ({ label, filePath, onUpload, onDelete }) => {
	const [uploadingFile, setUploadingFile] = useState<File>()

	const content =
		filePath !== null ? (
			<Preview imageFilePath={filePath} onDelete={onDelete} />
		) : uploadingFile ? (
			<Uploading file={{ name: uploadingFile.name, size: uploadingFile.size }} />
		) : (
			<Dropzone
				onImageDrop={async (file: File) => {
					setUploadingFile(file)
					await onUpload(file)
					setUploadingFile(undefined)
				}}
			/>
		)

	if (label === undefined) return content

	return (
		<div>
			<InputLabel>{label}</InputLabel>
			{content}
		</div>
	)
}

export default ImageUploader
