import { useState, type FC } from "react"
import { useNavigate } from "react-router"

import { type Bundle } from "@forento/shared/models/bundle"
import { type Course } from "@forento/shared/models/course"
import { type Downloadable } from "@forento/shared/models/downloadable"
import { type ThinEvent } from "@forento/shared/models/event"
import { type ThinPrivateSession } from "@forento/shared/models/privateSession"
import { purchaseableTypes, type PurchaseableType } from "@forento/shared/models/product"
import { type ThinProductAccessEmail } from "@forento/shared/models/studentEmail"
import { type Video } from "@forento/shared/models/video"

import Button from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

function filter<T extends { id: number; price: object | null }>(
	type: PurchaseableType,
	items: T[],
	emails: ThinProductAccessEmail[],
) {
	return items.filter(item => !emails.some(email => email.product.type === type && email.product.id === item.id))
}

type Props = { isOpen: boolean; onClose(): void; onReload(): Promise<unknown> }
const CreateModal: FC<Props> = ({ isOpen, onClose, onReload }) => {
	const args = [undefined, { enabled: isOpen }] as const

	const { data: productAccessEmails, error: productAccessEmailsError } =
		query.studentEmails.listProductAccess.useQuery(...args)
	const { data: courses, error: coursesError } = query.course.list.useQuery(...args)
	const { data: videos, error: videosError } = query.video.list.useQuery(...args)
	const { data: downloadables, error: downloadablesError } = query.downloadable.list.useQuery(...args)
	const { data: sessions, error: sessionsError } = query.privateSession.list.useQuery(...args)
	const { data: events, error: eventsError } = query.event.list.useQuery(...args)
	const { data: bundles, error: bundlesError } = query.bundle.list.useQuery(...args)

	return (
		<DefaultModal isOpen={isOpen}>
			<ModalTitle>Create new product access email</ModalTitle>
			{productAccessEmailsError ||
			coursesError ||
			videosError ||
			downloadablesError ||
			sessionsError ||
			eventsError ||
			bundlesError ? (
				<>
					<ModalText>Failed to load data. Please try again.</ModalText>
					<ModalButtons>
						<Button variant="primary">Close</Button>
					</ModalButtons>
				</>
			) : !productAccessEmails || !courses || !videos || !downloadables || !sessions || !events || !bundles ? (
				<PartialLoadingPage />
			) : (
				<>
					<Content
						courses={filter("course", courses, productAccessEmails)}
						videos={filter("video", videos, productAccessEmails)}
						downloadables={filter("downloadable", downloadables, productAccessEmails)}
						sessions={filter("session", sessions, productAccessEmails)}
						events={filter("event", events, productAccessEmails)}
						bundles={filter("bundle", bundles, productAccessEmails)}
						onClose={onClose}
						onReload={onReload}
					/>
				</>
			)}
		</DefaultModal>
	)
}

type ContentProps = {
	courses: Course[]
	videos: Video[]
	downloadables: Downloadable[]
	sessions: ThinPrivateSession[]
	events: ThinEvent[]
	bundles: Bundle[]
	onClose(): void
	onReload(): Promise<unknown>
}
const Content: FC<ContentProps> = ({
	courses,
	videos,
	downloadables,
	sessions,
	events,
	bundles,
	onClose,
	onReload,
}) => {
	const alert = useAlert()
	const navigate = useNavigate()

	const [selectedProduct, setSelectedProduct] = useState<{ type: PurchaseableType; id: number }>()
	const [title, setTitle] = useState("")

	const create = query.studentEmails.createProductAccess.useMutation({
		async onSuccess({ id }) {
			await onReload()
			navigate(routes.settings.studentEmail.editProductAccess(id))
		},
		async onError(error) {
			console.error(error)
			await alert.show("Error", "Failed to create product access email. Please try again.")
		},
	})

	const items: { id: string; title: string; isDisabled?: boolean }[] = [{ id: "select", title: "Select product" }]
	if (courses.length > 0) {
		items.push({ id: "courses", title: "--- Courses ---", isDisabled: true })
		items.push(...courses.map(course => ({ id: `course-${course.id}`, title: course.title })))
	}
	if (videos.length > 0) {
		items.push({ id: "videos", title: "--- Videos ---", isDisabled: true })
		items.push(...videos.map(video => ({ id: `video-${video.id}`, title: video.title })))
	}
	if (downloadables.length > 0) {
		items.push({ id: "downloadables", title: "--- Downloadables ---", isDisabled: true })
		items.push(
			...downloadables.map(downloadable => ({
				id: `downloadable-${downloadable.id}`,
				title: downloadable.title,
			})),
		)
	}
	if (sessions.length > 0) {
		items.push({ id: "sessions", title: "--- Sessions ---", isDisabled: true })
		items.push(...sessions.map(session => ({ id: `session-${session.id}`, title: session.title })))
	}
	if (events.length > 0) {
		items.push({ id: "events", title: "--- Events ---", isDisabled: true })
		items.push(...events.map(event => ({ id: `event-${event.id}`, title: event.title })))
	}
	if (bundles.length > 0) {
		items.push({ id: "bundles", title: "--- Bundles ---", isDisabled: true })
		items.push(...bundles.map(bundle => ({ id: `bundle-${bundle.id}`, title: bundle.name })))
	}

	return (
		<>
			<ModalInputs>
				<Dropdown
					items={items}
					selectedItemId={selectedProduct ? `${selectedProduct.type}-${selectedProduct.id}` : "select"}
					onChange={value => {
						const [typeString, id] = value.split("-")
						const type = purchaseableTypes.find(x => x === typeString)
						setSelectedProduct(type ? { type, id: Number(id) } : undefined)
					}}
				/>
				<InputField value={title} label="Email subject" onChange={setTitle} />
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={create.isPending}>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						if (title.trim().length === 0 || !selectedProduct) return
						create.mutate({ title, product: selectedProduct })
					}}
					isLoading={create.isPending}
				>
					Create
				</Button>
			</ModalButtons>
		</>
	)
}

export default CreateModal
