import { type FC, useEffect, useState } from "react"
import { HexColorPicker } from "react-colorful"
import styled, { css } from "styled-components"

import { platformLanguageIds, platformLanguages } from "@forento/shared/models/platform"
import { getDataUrlByFile } from "@forento/shared/utilities/file"
import regex from "@forento/shared/utilities/regex"
import { replaceAll } from "@forento/shared/utilities/string"

import Button from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import Dropdown from "~/components/Dropdown"
import ImageUploader from "~/components/ImageUploader"
import InputField, { InputTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import { useUser } from "~/contexts/UserContext"
import SlugModal from "~/pages/settings/PlatformSettingsPage/SlugModal"
import routes from "~/utilities/routes"
import { dangerColor, primaryColor, successColor } from "~/utilities/styles"
import trpc, { query } from "~/utilities/trpc"

import legacyThemeImage from "~/assets/themes/legacy.png"
import modernThemeImage from "~/assets/themes/modern.png"

import CurrencyModal from "./CurrencyModal"
import CustomEmailModal from "./CustomEmailModal"
import Preview from "./Preview"

const PlatformSettingsPage: FC = () => {
	const user = useUser()
	const alert = useAlert()
	const toast = useToast()

	const platform = user.user!.platform!

	const [name, setName] = useState(platform.name)
	const [title, setTitle] = useState(platform.title ?? "")
	const [description, setDescription] = useState(platform.description ?? "")
	const [language, setLanguage] = useState(platform.language)
	const [isSignupsOpen, setSignupsOpen] = useState(platform.isSignupsOpen)
	const [isPhoneNumberEnabled, setPhoneNumberEnabled] = useState(platform.isPhoneNumberEnabled)
	const [primaryColor, setPrimaryColor] = useState(platform.primaryColor.isCustom ? platform.primaryColor.value : "")

	const [isSlugModalOpen, setSlugModalOpen] = useState(false)
	const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false)
	const [isCustomEmailModalOpen, setCustomEmailModalOpen] = useState(false)

	const setFavicon = query.platform.setFavicon.useMutation({
		async onSuccess() {
			await user.reload()
			toast.showChangesSaved("Favicon")
		},
	})
	const deleteFavicon = query.platform.deleteFavicon.useMutation({
		async onSuccess() {
			await user.reload()
			toast.showChangesSaved("Favicon")
		},
	})

	useEffect(() => {
		const isModified =
			name !== platform.name ||
			title !== (platform.title ?? "") ||
			description !== (platform.description ?? "") ||
			isSignupsOpen !== platform.isSignupsOpen ||
			isPhoneNumberEnabled !== platform.isPhoneNumberEnabled ||
			primaryColor !== (platform.primaryColor.isCustom ? platform.primaryColor.value : "") ||
			language !== platform.language

		if (!isModified) {
			toast.clearUnsavedChanges()
		} else {
			toast.setUnsavedChanges(async () => {
				const hasPrimaryColor = primaryColor.length > 0 && primaryColor !== "#"
				if (hasPrimaryColor) {
					if (!regex.hexColor.test(primaryColor)) {
						await alert.show(
							"Invalid primary color",
							"Invalid primary color. It should be a hex value in the format #XXXXXX.",
						)
						return false
					}
				}

				if (platform.isPhoneNumberEnabled && !isPhoneNumberEnabled) {
					const result = await alert.confirm(
						"Disable phone number collection",
						"You are about to disable phone number collection. This will remove all phone numbers from your academy. Are you sure you want to continue?",
					)
					if (!result.result) return false
					result.close()
				}

				try {
					await trpc.platform.update.mutate({
						name,
						title,
						description,
						isSignupsOpen,
						isPhoneNumberEnabled,
						primaryColor: hasPrimaryColor ? primaryColor : null,
						language,
					})
					await user.reload()

					return true
				} catch (error) {
					console.error(error)
				}

				return false
			})
		}
	}, [
		alert,
		description,
		isPhoneNumberEnabled,
		isSignupsOpen,
		language,
		name,
		platform.description,
		platform.isPhoneNumberEnabled,
		platform.isSignupsOpen,
		platform.language,
		platform.name,
		platform.primaryColor.isCustom,
		platform.primaryColor.value,
		platform.title,
		primaryColor,
		title,
		toast,
		user,
	])

	const handleUpdateLogo = async (file: File) => {
		await trpc.platform.setLogo.mutate({ dataUrl: await getDataUrlByFile(file) })
		await user.reload()
		toast.showChangesSaved("Logo")
	}

	const handleDeleteLogo = async () => {
		await trpc.platform.deleteLogo.mutate()
		await user.reload()
		toast.showChangesSaved("Logo")
	}

	const handleUpdateThumbnail = async (file: File) => {
		await trpc.platform.setThumbnail.mutate({ dataUrl: await getDataUrlByFile(file) })
		await user.reload()
		toast.showChangesSaved("Thumbnail")
	}

	const handleDeleteThumbnail = async () => {
		await trpc.platform.deleteThumbnail.mutate()
		await user.reload()
		toast.showChangesSaved("Thumbnail")
	}

	return (
		<Layout
			asideContent={
				<Preview
					title={title.trim().length > 0 ? title.trim() : name.trim()}
					description={description}
					imageFileUrl={platform.thumbnailFilePath ?? ""}
				/>
			}
		>
			<SlugModal isOpen={isSlugModalOpen} onClose={() => setSlugModalOpen(false)} />
			<CurrencyModal isOpen={isCurrencyModalOpen} onClose={() => setCurrencyModalOpen(false)} />
			<CustomEmailModal isOpen={isCustomEmailModalOpen} setOpen={setCustomEmailModalOpen} />
			<div>
				<PageBreadcrumb
					title="Customize Academy"
					path={[{ title: "Settings", link: routes.settings.index() }]}
				/>
				<p>Basic information about the academy and how it looks like.</p>
				<Section>
					<div>
						<InputField label="Name" value={name} onChange={setName} />
						<InputDescription>
							The name is displayed on various places around your academy.
						</InputDescription>
					</div>
					<div>
						<InputField label="Title" value={title} onChange={setTitle} />
						<InputDescription>
							The title is displayed when listed on a search engine or shared on social media. If left
							empty, it will be the name of the academy.
						</InputDescription>
					</div>
					<div>
						<InputTextArea label="Description" value={description} onChange={setDescription} />
						<InputDescription>
							The description are displayed when listed on a search engine or shared on social media.
						</InputDescription>
					</div>
					<div>
						<InputLabel>Language</InputLabel>
						<Dropdown
							items={Object.entries(platformLanguages).map(([id, title]) => ({ id, title }))}
							selectedItemId={platform.theme.id === 2 ? language : "en"}
							onChange={async id => setLanguage(platformLanguageIds.find(x => x === id) ?? "en")}
							isDisabled={platform.theme.id !== 2}
						/>
						{platform.theme.id !== 2 && (
							<InputDescription>Languages are only available for the Modern theme.</InputDescription>
						)}
					</div>
					{platform.enabledFeatures.includes("block-signups") && (
						<Checkbox
							label="Allow non-invited users to sign up"
							isChecked={isSignupsOpen}
							onChange={setSignupsOpen}
						/>
					)}
					<Checkbox
						label="Enable phone number collection"
						isChecked={isPhoneNumberEnabled}
						onChange={setPhoneNumberEnabled}
					/>
					<InputField
						label="Brand color (primary)"
						placeholder="#XXXXXX"
						value={primaryColor}
						onChange={newValue => {
							setPrimaryColor(`#${replaceAll(newValue, "#", "").trim().substring(0, 6)}`)
						}}
						onFocus={() => {
							if (primaryColor.length === 0) setPrimaryColor("#")
						}}
					/>
					<HexColorPicker color={primaryColor} onChange={setPrimaryColor} />
				</Section>
				<Section>
					<div>
						<InputLabel>Logo</InputLabel>
						<ImageUploader
							filePath={platform.logo?.filePath ?? null}
							onUpload={handleUpdateLogo}
							onDelete={handleDeleteLogo}
						/>
					</div>

					{platform.logo && (
						<Dropdown
							items={[
								{ id: "logo", title: "Show only logo" },
								{ id: "icon", title: "Show logo and text" },
							]}
							onChange={async id => {
								await trpc.platform.update.mutate({ isLogoIcon: id === "icon" })
								await user.reload()
								toast.showChangesSaved("Logo")
							}}
							selectedItemId={platform.logo?.isIcon ? "icon" : "logo"}
						/>
					)}

					<div>
						<InputLabel>Favicon</InputLabel>
						<ImageUploaderDescription>
							Upload an image to use as your favicon. If your logo is just an icon, we recommend using it.
							You don't need to convert the image yourself - just upload it and we'll handle all the
							necessary conversions.
						</ImageUploaderDescription>
						<ImageUploader
							filePath={platform.faviconPath}
							onUpload={async file => setFavicon.mutateAsync({ dataUrl: await getDataUrlByFile(file) })}
							onDelete={() => deleteFavicon.mutateAsync()}
						/>
						{platform.logo?.isIcon && (
							<ImportFaviconButton
								variant="secondary"
								onClick={async () => {
									const file = await fetch(platform.logo!.filePath).then(async x => x.blob())
									setFavicon.mutateAsync({
										dataUrl: await getDataUrlByFile(
											new File([file], "Favicon", { type: file.type }),
										),
									})
								}}
								isLoading={setFavicon.isPending}
							>
								Import uploaded logo
							</ImportFaviconButton>
						)}
					</div>

					<div>
						<InputLabel>Thumbnail</InputLabel>
						<ImageUploaderDescription>
							The thumbnail will be displayed both when shared on social media, as well as throughout the
							academy.
						</ImageUploaderDescription>
						<ImageUploader
							filePath={platform.thumbnailFilePath}
							onUpload={handleUpdateThumbnail}
							onDelete={handleDeleteThumbnail}
						/>
					</div>

					<div>
						<InputLabel>Theme</InputLabel>
						<Themes>
							{[
								{ id: 2, name: "Modern", image: modernThemeImage },
								{ id: 1, name: "Legacy", image: legacyThemeImage },
							].map(theme => (
								<Theme
									key={theme.id}
									isActive={platform.theme.id === theme.id}
									onClick={async () => {
										await trpc.platform.setTheme.mutate(theme.id)
										await user.reload()
										toast.showChangesSaved("Theme")
									}}
								>
									<ThemeLabel>{theme.name}</ThemeLabel>
									<ThemeImage alt={`${theme.name} theme`} src={theme.image} />
								</Theme>
							))}
						</Themes>
					</div>
				</Section>
				<Section>
					<EmailTitle>Domain slug</EmailTitle>
					<EmailText>
						Academy: <strong>{platform.slug.platform}</strong>.forento.app
					</EmailText>
					{platform.slug.landingPage && (
						<EmailText>
							Website: <strong>{platform.slug.landingPage}</strong>.forento.site
						</EmailText>
					)}
					<StyledButton variant="primary" onClick={() => setSlugModalOpen(true)}>
						Change domain slug
					</StyledButton>
				</Section>
				{platform.paymentGateway?.isEnabled && (
					<Section>
						<EmailTitle>Currency</EmailTitle>
						<EmailText>Selected currency: {platform.currency}</EmailText>
						<StyledButton variant="primary" onClick={() => setCurrencyModalOpen(true)}>
							Change currency
						</StyledButton>
					</Section>
				)}
				<Section>
					<EmailTitle>Email sender</EmailTitle>
					<EmailText>
						Status:{" "}
						{platform.customEmailSender !== null ? (
							<>
								<EmailStatusText $isConnected>Connected</EmailStatusText> ({platform.customEmailSender})
							</>
						) : (
							<EmailStatusText>Not connected</EmailStatusText>
						)}
					</EmailText>
					<StyledButton variant="primary" onClick={() => setCustomEmailModalOpen(true)}>
						Connect custom email sender
					</StyledButton>
				</Section>
			</div>
		</Layout>
	)
}

const Section = styled.div`
	padding: 25px 0;
	display: flex;
	flex-direction: column;
	gap: 20px;

	&:not(:last-child) {
		padding-bottom: 26px;
		border-bottom: 1px solid #eeeeee;
	}
`

const ImportFaviconButton = styled(Button)`
	margin-top: 15px;
`

const EmailTitle = styled.h3`
	font-weight: 600;
	font-size: 14px;
`

const EmailText = styled.p`
	line-height: 1.4;
`

const ImageUploaderDescription = styled.p`
	color: #747474;
	margin-bottom: 15px;
`

const Themes = styled.div`
	display: flex;
	gap: 16px;
`

const ThemeLabel = styled.div`
	width: 100%;
	font-size: 14px;
	color: #757575;
	text-align: center;
`

const ThemeImage = styled.img`
	width: 100%;
	border-radius: 8px;
`

const Theme = styled(Button)<{ isActive?: boolean }>`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	gap: 8px;

	${props =>
		props.isActive &&
		css`
			${ThemeLabel} {
				color: ${primaryColor};
			}

			${ThemeImage} {
				outline: 4px solid ${primaryColor};
				outline-offset: -4px;
			}
		`}
`

const InputDescription = styled.p`
	color: #747474;
	margin-top: 6px;
`

const EmailStatusText = styled.span<{ $isConnected?: boolean }>`
	color: ${props => (props.$isConnected ? successColor : dangerColor)};
`

const StyledButton = styled(Button)`
	align-self: flex-start;
`

export default PlatformSettingsPage
