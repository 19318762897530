import { type FC, useState } from "react"
import styled from "styled-components"

import countries, { countryCodes } from "@forento/shared/models/countries"

import Button from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import Layout, { PageTitle } from "~/components/Layout"
import { DefaultModal, ModalButtons, ModalContentPadding, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import { lightTextColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import paypalIllustration from "./paypal.svg"
import stripeIllustration from "./stripe.svg"

const ConnectGateway: FC = () => {
	const alert = useAlert()

	const [isModalOpen, setModalOpen] = useState(false)
	const [selectedCountryCode, setSelectedCountryCode] = useState<(typeof countries)[number]["code"]>()
	const [isSubmittingStripe, setSubmittingStripe] = useState(false)
	const [isSubmittingPayPal, setSubmittingPayPal] = useState(false)

	async function handleConnectStripe() {
		const selectedCountry = countries.find(x => x.code === selectedCountryCode)
		if (!selectedCountry) return

		setSubmittingStripe(true)
		try {
			await trpc.platform.update.mutate({ country: selectedCountry.code })
		} catch (error) {
			console.error(error)
			setSubmittingStripe(false)
			return
		}
		if (!selectedCountry.hasStripe) {
			await alert.show(
				"Stripe is not supported",
				"We're sorry, but Stripe is not available in your country. Please try PayPal instead.",
			)
			setSubmittingStripe(false)
			return
		}
		if (!selectedCountry.hasCommissions) {
			await alert.show(
				"Limited Stripe support",
				"We're sorry, but Stripe support in your country is limited. This means that vital features used by us are not available. Please try PayPal instead.",
			)
			setSubmittingStripe(false)
			return
		}

		try {
			const { redirectUrl } = await trpc.payment.stripe.createOnboarding.mutate()
			window.location.href = redirectUrl
		} catch (error) {
			console.error(error)
			setSubmittingStripe(false)
		}
	}

	async function handleConnectPayPal() {
		setSubmittingPayPal(true)
		try {
			const { redirectUrl } = await trpc.payment.paypal.createOnboarding.mutate()
			window.location.href = redirectUrl
		} catch (error) {
			console.error(error)
			setSubmittingPayPal(false)
		}
	}

	return (
		<Layout>
			<DefaultModal isOpen={isModalOpen}>
				<ModalTitle>Choose your country</ModalTitle>
				<ModalContentPadding>
					<Dropdown
						items={[
							{ id: "NONE", title: "-- Select country --" },
							...countries.map(x => ({ id: x.code, title: x.label })),
						]}
						selectedItemId={selectedCountryCode ?? "NONE"}
						onChange={value => setSelectedCountryCode(countryCodes.find(x => x === value))}
					/>
				</ModalContentPadding>
				<ModalButtons>
					<Button variant="secondary" onClick={() => setModalOpen(false)}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleConnectStripe}
						isDisabled={!selectedCountryCode}
						isLoading={isSubmittingStripe}
					>
						Connect
					</Button>
				</ModalButtons>
			</DefaultModal>
			<PageTitle>Choose a payment gateway</PageTitle>
			<Options>
				<Option>
					<Illustration src={stripeIllustration} alt="Stripe illustration" />
					<Title>Stripe</Title>
					<Text>
						Connect with Stripe to receive payments from your customers. We'll handle all payments for you!
					</Text>
					<Button variant="primary" onClick={() => setModalOpen(true)}>
						Connect with Stripe
					</Button>
				</Option>
				<Option>
					<Illustration src={paypalIllustration} alt="PayPal illustration" />
					<Title>PayPal</Title>
					<Text>
						Connect with PayPal to receive payments from your customers. We'll handle all payments for you!
					</Text>
					<Button variant="primary" onClick={handleConnectPayPal} isLoading={isSubmittingPayPal}>
						Connect with PayPal
					</Button>
				</Option>
			</Options>
		</Layout>
	)
}

const Options = styled.div`
	display: flex;
	gap: 16px;
`

const Option = styled.div`
	position: relative;
	background-color: #fbfaf8;
	border: 1px solid #eeeeed;
	border-radius: 8px;
	padding: 42px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`

const Illustration = styled.img`
	width: 100px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
`

const Text = styled.p`
	color: ${lightTextColor};
	text-align: center;
	max-width: 500px;
`

export default ConnectGateway
