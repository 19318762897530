import { type FC } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"

import Button from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import { ArrowIcon, EditIcon } from "~/components/Icon"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useToast } from "~/contexts/ToastContext"
import { usePlatform, useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

import ListItem from "./ListItem"

const StudentEmailsSettingsPage: FC = () => {
	const user = useUser()
	const platform = usePlatform()!
	const toast = useToast()

	const { data: emails, error, refetch } = query.studentEmails.list.useQuery()

	const updateEmail = query.studentEmails.update.useMutation({
		async onSuccess() {
			await refetch()
			await user.reload()
		},
		onError(error) {
			console.error(error)
			toast.showError("Failed to update email. Please try again.")
		},
	})

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb title="Student Emails" path={[{ title: "Settings", link: routes.settings.index() }]} />
				<Button variant="primary" onClick={routes.settings.studentEmail.layout()}>
					Edit layout
				</Button>
			</PageHeader>
			{error ? (
				<p>Failed to load student emails.</p>
			) : !emails ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<ListItem
						label="Product access"
						description="Send an email to students when they access a product for the first time."
						isLocked={platform.plan.maxProductAccessEmails === 0}
						actions={
							<Action onClick={routes.settings.studentEmail.productAccess()}>
								<ArrowIcon />
							</Action>
						}
					/>
					{emails.map(email => (
						<ListItem
							key={email.id}
							label={email.label}
							description={email.description}
							isLocked={
								(email.id === "chapter-dripped" && !platform.plan.access.chapterDripping) ||
								(email.id === "empty-student-created" && !platform.plan.access.zapier)
							}
							actions={
								<>
									<Tooltip tooltip="Edit email">
										<Action onClick={routes.settings.studentEmail.detail(email.id)}>
											<EditIcon />
										</Action>
									</Tooltip>
									<Tooltip tooltip={email.isRequired ? "This email is required" : "Enable email"}>
										<Checkbox
											isChecked={email.isEnabled}
											onChange={isEnabled =>
												updateEmail.mutateAsync({ emailId: email.id, data: { isEnabled } })
											}
											isLoading={
												updateEmail.isPending && updateEmail.variables?.emailId === email.id
											}
											isDisabled={updateEmail.isPending || email.isRequired}
										/>
									</Tooltip>
								</>
							}
						/>
					))}
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Action = styled(Button)`
	width: 24px;
	height: 24px;
`

export default StudentEmailsSettingsPage
