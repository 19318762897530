import { type FC } from "react"
import styled from "styled-components"

import { type CreatorNotificationId } from "@forento/shared/models/user"

import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

import Notification from "./Notification"

const options: { id: CreatorNotificationId; label: string }[] = [
	{ id: "newUser", label: "New user" },
	{ id: "newPurchase", label: "New purchase" },
	{ id: "newMembership", label: "New membership" },
	{ id: "newCourseComment", label: "New course comment" },
	{ id: "newCommunityProfile", label: "New community profile" },
	{ id: "newCommunityPost", label: "New community post" },
	{ id: "newCommunityComment", label: "New community comment" },
	{ id: "newMessage", label: "New message" },
]

const NotificationsSettingsPage: FC = () => {
	const { data, error, refetch } = query.user.getCreatorNotificationSettings.useQuery()

	return (
		<Layout>
			<PageBreadcrumb title="Notifications" path={[{ title: "Settings", link: routes.settings.index() }]} />
			{error ? (
				<p>Failed to load notification settings.</p>
			) : !data ? (
				<PartialLoadingPage />
			) : (
				<Content>
					{options.map(option => (
						<Notification
							key={option.id}
							option={option}
							isChecked={data[option.id]}
							setChecked={async value => {
								await trpc.user.setCreatorNotificationSetting.mutate({ setting: option.id, value })
								await refetch()
							}}
						/>
					))}
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default NotificationsSettingsPage
