import { useEffect, useState } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"
import { parseNumber } from "@forento/shared/utilities/number"

import { SubmitButton } from "~/components/Button"
import { SendIcon } from "~/components/Icon"
import InputField from "~/components/InputField"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useToast } from "~/contexts/ToastContext"
import { useUser } from "~/contexts/UserContext"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import routes from "~/utilities/routes"
import { primaryColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

const ConversationPage: React.FC = () => {
	const otherProfileId = parseNumber(useParams().otherProfileId ?? "") ?? -1
	const user = useUser()
	const toast = useToast()

	const [messagesElement, setMessagesElement] = useState<HTMLDivElement | null>(null)
	const [input, setInput] = useState("")

	const {
		data: conversation,
		error,
		refetch,
	} = query.community.getConversation.useQuery(otherProfileId, { refetchInterval: 3000 })

	const send = query.community.sendMessage.useMutation({
		async onSuccess() {
			await refetch()
			setInput("")
			setTimeout(() => {
				messagesElement?.scrollTo({ top: messagesElement.scrollHeight, behavior: "smooth" })
			}, 0)
		},
		onError(error) {
			console.error(error)
			toast.showError("Failed to send message. Please try again.")
		},
	})

	useEffect(() => {
		if (messagesElement) {
			messagesElement.scrollTop = messagesElement.scrollHeight
		}
	}, [messagesElement])

	if (conversation === null) return <Navigate replace to={routes.community.messages()} />

	return (
		<Layout>
			<PageBreadcrumb
				path={[{ title: "Messages", link: routes.community.messages() }]}
				title={
					conversation !== undefined
						? `${conversation.otherProfile.firstName} ${conversation.otherProfile.lastName}`
						: "..."
				}
			/>
			{error ? (
				<p>Failed to load conversation.</p>
			) : conversation === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					<Messages ref={setMessagesElement}>
						{conversation.messages.map(x => {
							const isSent = x.senderProfileId === user.user!.communityProfileId
							return (
								<MessageContainer key={x.id} $isSent={isSent}>
									<MessageText $isSent={isSent}>{x.text}</MessageText>
								</MessageContainer>
							)
						})}
					</Messages>
					<InputContainer onSubmit={() => send.mutate({ otherProfileId, text: input })}>
						<StyledInputField placeholder="Type your message..." value={input} onChange={setInput} />
						<SendButton isDisabled={input.trim().length === 0} isLoading={send.isPending} replaceOnLoading>
							<StyledSendIcon />
						</SendButton>
					</InputContainer>
				</>
			)}
		</Layout>
	)
}

const Messages = styled.div`
	flex: 1 0 0;
	width: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 16px;
`

const MessageContainer = styled.div<{ $isSent: boolean }>`
	width: 40%;
	align-self: ${props => (props.$isSent ? "flex-end" : "flex-start")};
`

const MessageText = styled.p<{ $isSent: boolean }>`
	background-color: ${props => (props.$isSent ? primaryColor : "white")};
	color: ${props => (props.$isSent ? "white" : "black")};
	padding: 8px 16px;
	border-radius: 16px;
`

const InputContainer = styled(Form)`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 16px;
`

const StyledInputField = styled(InputField)`
	flex: 1;
`

const SendButton = styled(SubmitButton).attrs({ variant: "primary" })`
	width: 52px;
	height: 52px;
	padding: 16px;
`

const StyledSendIcon = styled(SendIcon)`
	width: 100%;
	height: 100%;
	color: white;
`

export default withAccessRequirement(
	"community",
	<PageBreadcrumb path={[{ title: "Messages", link: routes.community.messages() }]} title="Conversation" />,
	ConversationPage,
)
