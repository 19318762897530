import dayjs from "dayjs"
import { type FC, useState } from "react"
import styled from "styled-components"

import { toggleStateArray } from "@forento/shared/utilities/array"
import { toNullIfEmpty } from "@forento/shared/utilities/string"

import Button from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import ImageUploader from "~/components/ImageUploader"
import InputField, { InputTextArea, useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import { DefaultModal, ModalButtons, ModalContentPadding, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/UserContext"
import { useImageFile } from "~/hooks/useImageFile"
import usePrice from "~/hooks/usePrice"
import { query } from "~/utilities/trpc"

type Props = { isOpen: boolean; onClose(): void; onCreated(id: number): Promise<void> }
const CreateEventModal: FC<Props> = ({ isOpen, onClose, onCreated }) => {
	const alert = useAlert()
	const platform = usePlatform()!

	const now = dayjs()

	const [title, setTitle] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const [startDate, setStartDate] = useState(now.set("minute", 0).add(1, "hour"))
	const [endDate, setEndDate] = useState(now.set("minute", 0).add(2, "hour"))
	const [isPaid, setPaid] = useState(false)
	const { price, setPrice, priceValue } = usePrice()
	const [location, setLocation] = useState("")
	const [videoCallLink, setVideoCallLink] = useState("")
	const [membershipIds, setMembershipIds] = useState<number[]>([])

	const thumbnail = useImageFile(0, null)

	const memberships = query.membership.list.useQuery(undefined, { enabled: isOpen, refetchOnWindowFocus: false })

	const create = query.event.create.useMutation({
		async onSuccess(created) {
			setTitle("")
			setShortDescription("")
			longDescription.clear()
			thumbnail.clear()
			setStartDate(now.set("minute", 0).add(1, "hour"))
			setEndDate(now.set("minute", 0).add(2, "hour"))
			setPaid(false)
			setPrice("")
			setLocation("")
			setVideoCallLink("")
			setMembershipIds([])

			await onCreated(created.id)
		},
		async onError(error) {
			console.error(error)
			await alert.show("Failed to create event", "An error occurred while creating the event. Please try again.")
		},
	})

	return (
		<DefaultModal isOpen={isOpen}>
			<ModalTitle>Create a new event</ModalTitle>
			{memberships.error ? (
				<ModalContentPadding>Failed to load</ModalContentPadding>
			) : !memberships.data ? (
				<PartialLoadingPage />
			) : (
				<ModalInputs>
					<InputField label="Title *" value={title} onChange={setTitle} />
					<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
					{longDescription.element}
					<ImageUploader label="Thumbnail" {...thumbnail.imageUploader} />
					<InputField
						inputType="datetime-local"
						label="Start date *"
						value={startDate.format("YYYY-MM-DD HH:mm")}
						onChange={value => setStartDate(dayjs(value))}
					/>
					<InputField
						inputType="datetime-local"
						label="End date *"
						value={endDate.format("YYYY-MM-DD HH:mm")}
						onChange={value => setEndDate(dayjs(value))}
					/>
					<div>
						<StyledCheckbox label="Is paid" isChecked={isPaid} onChange={setPaid} />
						<InputField
							label="Price"
							value={price}
							inputType="number"
							onChange={setPrice}
							disabled={!isPaid}
						/>
					</div>
					<InputTextArea label="Location" value={location} onChange={setLocation} />
					<InputField label="Video call link" value={videoCallLink} onChange={setVideoCallLink} />
					{memberships.data.length > 0 && (
						<div>
							<InputLabel>Include in memberships</InputLabel>
							<Memberships>
								{memberships.data.map(membership => (
									<Checkbox
										key={membership.id}
										label={membership.title}
										isChecked={membershipIds.includes(membership.id)}
										onChange={() => toggleStateArray(setMembershipIds, membership.id)}
									/>
								))}
							</Memberships>
						</div>
					)}
				</ModalInputs>
			)}
			<ModalText>
				{platform.enabledStudentEmails.includes("event-reminder")
					? "An email reminder will be sent to all enrolled members 24 hours before the event. This can be changed in student email settings."
					: "No email reminder will be sent to enrolled members. This can be enabled in student email settings."}
			</ModalText>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={create.isPending}>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={async () => {
						if (startDate > endDate) {
							alert.show("Invalid date", "The start date must be before the end date.")
							return
						}
						create.mutate({
							title,
							shortDescription,
							longDescription: longDescription.exportEditorState(),
							thumbnailDataUrl: await thumbnail.exportToDataUrl(),
							startDate: startDate.toDate(),
							endDate: endDate.toDate(),
							price: isPaid ? priceValue : null,
							location: toNullIfEmpty(location.trim()) ?? null,
							videoCallLink: toNullIfEmpty(videoCallLink.trim()) ?? null,
							membershipIds,
						})
					}}
					isLoading={create.isPending}
					isDisabled={title.trim().length === 0}
				>
					Create event
				</Button>
			</ModalButtons>
		</DefaultModal>
	)
}

const StyledCheckbox = styled(Checkbox)`
	margin-bottom: 8px;
`

const Memberships = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 8px;
`

export default CreateEventModal
