import { type FC, useState } from "react"
import styled from "styled-components"

import { toDateTimeString } from "@forento/shared/utilities/date"

import { usePlatform, useUser } from "~/contexts/UserContext"
import { toRelativeDateString } from "~/utilities/date"
import routes from "~/utilities/routes"
import { pageContentWidth, primaryLightColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import Button from "../Button"

const StatusBanner: FC = () => {
	const user = useUser().user
	const platform = usePlatform()

	const [isResendingEmail, setResendingEmail] = useState(false)
	const [hasResentEmail, setResentEmail] = useState(false)

	const handleResendEmail = async () => {
		setResendingEmail(true)
		try {
			await trpc.user.sendEmailVerification.mutate()
			setResentEmail(true)
		} catch (error) {
			console.error(error)
		} finally {
			setResendingEmail(false)
		}
	}

	if (user !== undefined && !user.isEmailVerified) {
		return (
			<Container>
				<Content>
					<Text>Your email address is not verified. Please check your inbox.</Text>
					<CallToAction
						variant="primary"
						onClick={handleResendEmail}
						isLoading={isResendingEmail}
						isDisabled={hasResentEmail}
					>
						{hasResentEmail ? "Email sent" : "Resend email"}
					</CallToAction>
				</Content>
			</Container>
		)
	}

	if (platform === null) return null

	if (platform.plan.type === "regular" && platform.plan.endDate) {
		return (
			<Container>
				<Content>
					<Text>
						Automatic renewal is disabled. You will be downgraded to the free plan{" "}
						<span title={toDateTimeString(platform.plan.endDate)}>
							{toRelativeDateString(platform.plan.endDate)}
						</span>
						.
					</Text>
					<CallToAction variant="primary" onClick={routes.settings.billing.plans()}>
						Go to plans
					</CallToAction>
				</Content>
			</Container>
		)
	}

	if (platform.domain.isLegacyDnsHost) {
		return (
			<Container>
				<Content>
					<Text>
						Your domain is using a legacy DNS host. This host will soon be removed, so please update your
						DNS settings in order to avoid any disruptions.
					</Text>
					<CallToAction variant="primary" onClick={routes.settings.domain.index()}>
						Go to DNS settings
					</CallToAction>
				</Content>
			</Container>
		)
	}

	if (platform.plan.type === "appsumo" || platform.plan.type === "viededingue") {
		if (platform.plan.addon?.type === "trial") {
			return (
				<Container>
					<Content>
						<Text>
							Your Scale addon trial will end in {toRelativeDateString(platform.plan.addon.endDate)}.
							Enable renewal to continue using the Scale addon. You won't be charged until your trial
							expires.
						</Text>
						<CallToAction variant="primary" onClick={routes.settings.billing.plans()}>
							Go to addons
						</CallToAction>
					</Content>
				</Container>
			)
		}
	}

	return null
}

const Container = styled.div`
	padding: 16px 30px;
	background-color: ${primaryLightColor};
`

const Content = styled.div`
	box-sizing: border-box;
	width: 100%;
	max-width: ${pageContentWidth - 30 * 2}px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
`

const Text = styled.p`
	flex: 1 0 0;
`

const CallToAction = styled(Button)`
	flex: 0 0 auto;
`

export default StatusBanner
