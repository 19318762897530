import { type FC, useId } from "react"
import styled from "styled-components"

import { CheckmarkIcon } from "~/components/Icon"
import LoadingIndicator from "~/components/LoadingIndicator"
import { primaryColor } from "~/utilities/styles"

type Props = {
	label?: string
	isChecked: boolean
	onChange?(isChecked: boolean): void
	isBold?: boolean
	isDisabled?: boolean
	isLoading?: boolean
	className?: string
}

const Checkbox: FC<Props> = ({ label, isChecked, onChange, isBold, isDisabled, isLoading, className }) => {
	const id = useId()

	return (
		<div className={className}>
			<Input
				id={id}
				checked={isChecked}
				onChange={event => onChange?.(event.target.checked)}
				disabled={isDisabled}
			/>
			<Label htmlFor={id}>
				<Box>{isLoading ? <StyledLoadingIndicator /> : <StyledCheckmarkIcon />}</Box>
				{label !== undefined && <Text $isBold={isBold}>{label}</Text>}
			</Label>
		</div>
	)
}

const Label = styled.label`
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
`

const Box = styled.div`
	display: block;
	width: 20px;
	height: 20px;
	background-color: white;
	border: 1px solid #d0d5dd;
	border-radius: 4px;
	transition: 0.07s;
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
	scale: 0;
	width: 100%;
	height: 100%;
	padding: 2px;
	color: ${primaryColor};
	transition: 0.07s;
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
	width: 100%;
	height: 100%;
	padding: 4px;
`

const Input = styled.input.attrs({ type: "checkbox" })`
	display: none;

	&:checked ~ ${Label} ${StyledCheckmarkIcon} {
		scale: 1;
	}

	&:disabled ~ ${Label} ${Box} {
		background-color: #f5f7f9;

		${StyledCheckmarkIcon} {
			color: #d0d5dd;
		}
	}
`

const Text = styled.span<{ $isBold?: boolean }>`
	font-weight: ${props => (props.$isBold ? "700" : "500")};
	font-size: ${props => (props.$isBold ? "16px" : "14px")};
	letter-spacing: ${props => (props.$isBold ? "0.5px" : "0")};
	user-select: none;
`

export default Checkbox
