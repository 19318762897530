import { type FC } from "react"
import styled from "styled-components"

import { type BlogPost as BlogPostType } from "@forento/shared/models/dashboard"

import Button from "~/components/Button"

const BlogPost: FC<{ post: BlogPostType }> = ({ post }) => (
	<Container>
		{post.thumbnailFilePath && <Thumbnail src={post.thumbnailFilePath} />}
		<Title onClick={post.link}>{post.title}</Title>
	</Container>
)

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const Thumbnail = styled.img.attrs({ alt: "Thumbnail" })`
	flex: 0 0 auto;
	width: 100px;
	height: 100px;
	object-fit: cover;
	border-radius: 8px;
`

const Title = styled(Button)`
	flex: 1 0 0;
	font-size: 16px;
	font-weight: 600;
`

export default BlogPost
