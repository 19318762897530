import { type JSX, type ComponentType, type ReactNode } from "react"
import styled from "styled-components"

import { toPaymentGatewayString } from "@forento/shared/utilities/payment"

import Layout from "~/components/Layout"
import { useUser } from "~/contexts/UserContext"
import { lightTextColor } from "~/utilities/styles"

import illustrationPath from "./illustration.svg"

const withGatewayRequirement = <T extends JSX.IntrinsicAttributes>(
	gateway: "stripe" | "paypal",
	header: ReactNode,
	WrappedComponent: ComponentType<T>,
): React.FC<T> => {
	return props => {
		const platform = useUser().user!.platform!

		if (platform.paymentGateway?.type !== gateway)
			return (
				<Layout>
					{header}
					<Container>
						<Illustration alt="Illustration" src={illustrationPath} />
						<Title>This feature requires {toPaymentGatewayString(gateway)}</Title>
						<Text>
							We're sorry, but this feature is currently only supported by{" "}
							{toPaymentGatewayString(gateway)}.
							<br />
							We are working on this for all payment gateways.
						</Text>
					</Container>
				</Layout>
			)

		return <WrappedComponent {...props} />
	}
}

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eeeeed;
	border-radius: 8px;
	padding: 42px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`

const Illustration = styled.img`
	width: 100px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
`

const Text = styled.p`
	color: ${lightTextColor};
	text-align: center;
	max-width: 500px;
`

export default withGatewayRequirement
