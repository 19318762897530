import { type FC, useState } from "react"

import { toSlug } from "@forento/shared/utilities/string"

import Button, { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform, useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

const SlugModal: FC<{ isOpen: boolean; onClose(): void }> = ({ isOpen, onClose }) => {
	const alert = useAlert()
	const user = useUser()
	const platform = usePlatform()!

	const [platformSlug, setPlatformSlug] = useState(platform.slug.platform)
	const [landingPageSlug, setLandingPageSlug] = useState(platform.slug.landingPage ?? "")
	const [isSubmitting, setSubmitting] = useState(false)

	const isValid = platformSlug.trim().length > 0

	async function handleSubmit() {
		if (!isValid) return

		setSubmitting(true)
		try {
			const { status } = await trpc.platform.setSlug.mutate({
				platform: platformSlug,
				landingPage: landingPageSlug.trim().length > 0 ? landingPageSlug : undefined,
			})
			switch (status) {
				case "platform-taken": {
					await alert.show("Slug taken", "The academy slug is already taken by another academy.")
					break
				}
				case "landing-page-taken": {
					await alert.show("Slug taken", "The website slug is already taken by another academy.")
					break
				}
				case "success": {
					await user.reload()
					await alert.show("Slug updated", "Your slug has been updated.")
					setPlatformSlug("")
					setLandingPageSlug("")
					onClose()
					break
				}
			}
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Update domain slug</ModalTitle>
			<ModalText>If you want to change your default domain you can update your slug here.</ModalText>
			<ModalInputs>
				<InputField
					label="Academy slug (X.forento.app)"
					placeholder={platform.slug.platform}
					value={platformSlug}
					onChange={value => setPlatformSlug(toSlug(value))}
				/>
				{platform.slug.landingPage && (
					<InputField
						label="Website slug (X.forento.site)"
						placeholder={platform.slug.landingPage}
						value={landingPageSlug}
						onChange={value => setLandingPageSlug(toSlug(value))}
					/>
				)}
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isLoading={isSubmitting} isDisabled={!isValid}>
					Save
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

export default SlugModal
