import { createContext, useContext } from "react"

export type ToastContextValue = {
	showChangesSaved(changeName: string): void
	showPublished(productName: string): void
	showDeleted(deletedName: string): void
	showError(error: string): void
	showSuccess(success: string): void
	setUnsavedChanges(onSave: () => Promise<boolean>): void
	clearUnsavedChanges(): void
}

export const ToastContext = createContext<ToastContextValue>(undefined!)

export function useToast() {
	return useContext(ToastContext)
}
