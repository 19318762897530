import { type FC, useState } from "react"
import { useNavigate } from "react-router"
import styled from "styled-components"

import Button from "~/components/Button"
import ImageUploader from "~/components/ImageUploader"
import InputField, { InputTextArea, useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import ProductPricing from "~/components/ProductPricing"
import { useAlert } from "~/contexts/AlertContext"
import { useImageFile } from "~/hooks/useImageFile"
import usePrice from "~/hooks/usePrice"
import useProductPublishedAlert from "~/hooks/useProductPublishedAlert"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

const CreateSessionPage: FC = () => {
	const alert = useAlert()
	const publishedAlert = useProductPublishedAlert("session")
	const navigate = useNavigate()

	const [title, setTitle] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const { price, priceValue, setPrice } = usePrice()
	const [isSignInRequired, setSignInRequired] = useState(false)
	const [membershipIds, setMembershipIds] = useState<number[]>([])
	const [isPaused, setPaused] = useState(false)
	const [redemptionInstructions, setRedemptionInstructions] = useState("")
	const [redemptionLink, setRedemptionLink] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const thumbnail = useImageFile(0, null)

	async function handleSubmit() {
		setSubmitting(true)
		try {
			if (title.trim().length === 0) {
				return alert.show("Error", "You must fill in a title.")
			}
			if (redemptionInstructions.trim().length === 0 && redemptionLink.trim().length === 0) {
				return alert.show("Error", "You must provide either redemption instructions or a redemption link.")
			}
			const created = await trpc.privateSession.create.mutate({
				title,
				shortDescription,
				longDescription: longDescription.exportEditorState(),
				thumbnailDataUrl: await thumbnail.exportToDataUrl(),
				price: priceValue,
				isSignInRequired,
				membershipIds,
				isPaused,
				redemptionInstructions: redemptionInstructions.trim().length > 0 ? redemptionInstructions : null,
				redemptionLink: redemptionLink.trim().length > 0 ? redemptionLink : null,
			})
			await publishedAlert.show({ itemId: created.id })
			navigate(routes.sessions.index())
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<PageBreadcrumb title="Create" path={[{ title: "Sessions", link: routes.sessions.index() }]} />
			<Content>
				<InputField label="Title" value={title} onChange={setTitle} />
				<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
				{longDescription.element}
				<ImageUploader label="Thumbnail" {...thumbnail.imageUploader} />
				<InputTextArea
					label="Redemption instructions"
					value={redemptionInstructions}
					onChange={setRedemptionInstructions}
				/>
				<InputField
					label="Redemption link"
					value={redemptionLink}
					onChange={setRedemptionLink}
					placeholder="Calendar booking, etc."
				/>
				<InputLabel>Pricing</InputLabel>
				<ProductPricing
					type="session"
					price={price}
					setPrice={setPrice}
					isSignInRequired={isSignInRequired}
					setSignInRequired={setSignInRequired}
					membershipIds={membershipIds}
					setMembershipIds={setMembershipIds}
					isPaused={isPaused}
					setPaused={setPaused}
				/>
				<StyledButton variant="primary" onClick={handleSubmit} isLoading={isSubmitting}>
					Create session
				</StyledButton>
			</Content>
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const StyledButton = styled(Button)`
	align-self: flex-end;
`

export default CreateSessionPage
