import { type FC } from "react"
import styled from "styled-components"

import { type ThinEvent } from "@forento/shared/models/event"

import { ControlledTabs } from "~/components/Tabs"

import Event from "./Event"

type Props = { events: ThinEvent[]; onReload(): Promise<unknown> }
const Events: FC<Props> = ({ events, onReload }) => {
	const upcomingEvents = events.filter(event => event.endDate > new Date())
	const pastEvents = events.filter(event => !upcomingEvents.some(upcomingEvent => upcomingEvent.id === event.id))

	return (
		<ControlledTabs
			tabs={[
				{
					title: "Upcoming",
					sticker: upcomingEvents.length.toString(),
					content: (
						<List>
							{upcomingEvents.map(event => (
								<Event key={event.id} event={event} onReload={onReload} />
							))}
						</List>
					),
				},
				{
					title: "Past",
					sticker: pastEvents.length.toString(),
					content: (
						<List>
							{pastEvents.map(event => (
								<Event key={event.id} event={event} onReload={onReload} />
							))}
						</List>
					),
				},
			]}
		/>
	)
}

const List = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;
`

export default Events
