import { useState, type FC } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { type ThinEvent as EventType } from "@forento/shared/models/event"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Button from "~/components/Button"
import { DeleteIcon, EditIcon, UsersIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import { dangerColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

import AttendeesModal from "./AttendeesModal"
import EditModal from "./EditModal"

type Props = { event: EventType; onReload(): Promise<unknown> }
const Event: FC<Props> = ({ event, onReload }) => {
	const alert = useAlert()
	const toast = useToast()

	const [isEditOpen, setEditOpen] = useState(false)
	const [isAttendeesOpen, setAttendeesOpen] = useState(false)

	const remove = query.event.delete.useMutation({
		async onSuccess() {
			await onReload()
		},
		onError(error) {
			console.error(error)
			toast.showError("Failed to delete event. Please try again.")
		},
	})

	return (
		<Container>
			<EditModal eventId={event.id} isOpen={isEditOpen} onClose={() => setEditOpen(false)} onReload={onReload} />
			<AttendeesModal eventId={event.id} isOpen={isAttendeesOpen} onClose={() => setAttendeesOpen(false)} />

			<EventStartDate>{toDateTimeString(event.startDate)}</EventStartDate>
			<EventTitle>{event.title}</EventTitle>
			{event.shortDescription && <EventDescription>{event.shortDescription}</EventDescription>}
			<EventUsers>
				{event.price !== null && <p>Purchases: {event.users.orders.length}</p>}
				<p>Attending: {event.users.attendees.length}</p>
				<p>Interested: {event.users.interested.length}</p>
				<p>Not interested: {event.users.notInterested.length}</p>
			</EventUsers>
			<EventActions>
				<Tooltip tooltip="Attendees">
					<EventAction onClick={() => setAttendeesOpen(true)}>
						<UsersIcon />
					</EventAction>
				</Tooltip>
				<Tooltip tooltip="Edit">
					<EventAction onClick={() => setEditOpen(true)}>
						<EditIcon />
					</EventAction>
				</Tooltip>
				<Tooltip tooltip="Delete">
					<EventAction
						onClick={async () => {
							const dialog = await alert.confirm(
								"Delete event",
								`Are you sure you want to delete the event **${event.title}**?`,
							)
							if (!dialog.result) return

							await remove.mutateAsync(event.id)
							dialog.close()
						}}
					>
						<StyledDeleteIcon />
					</EventAction>
				</Tooltip>
			</EventActions>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
	display: grid;
	grid-template-areas:
		"start-date users actions"
		"title users actions"
		"description users actions";
	grid-template-columns: 1fr max-content max-content;
	grid-template-rows: max-content max-content max-content;
	column-gap: 32px;
`

const EventStartDate = styled.time`
	grid-area: start-date;
	font-size: 14px;
`

const EventTitle = styled.h2`
	grid-area: title;
	font-weight: 600;
	font-size: 20px;
`

const EventDescription = styled.p`
	grid-area: description;
`

const EventUsers = styled.div`
	grid-area: users;
	text-align: right;
`

const EventActions = styled.div`
	grid-area: actions;
	display: flex;
	align-items: center;
	gap: 16px;
`

const EventAction = styled(Button)`
	width: 24px;
	height: 24px;
`

const StyledDeleteIcon = styled(DeleteIcon)`
	color: ${dangerColor};
`

export default Event
