import posthog from "posthog-js"
import { type Dispatch, type FC, useState, useEffect } from "react"
import styled, { css } from "styled-components"

import Button from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import { pageContentWidth, primaryColor } from "~/utilities/styles"

const cookieName = "cookie-option"
const options = ["nec", "func", "ana"] as const
type CookieOption = (typeof options)[number]

const CookieConsent: FC = () => {
	const [cookieOptions, setCookieOptions] = useCookieOption()
	const [isCustomizeOpen, setCustomizeOpen] = useState(false)
	const [isFunctionalChecked, setFunctionalChecked] = useState(false)
	const [isAnalyticsChecked, setAnalyticsChecked] = useState(false)
	const [isLiveChatOpen, setLiveChatOpen] = useState(false)

	useEffect(() => {
		if (cookieOptions?.includes("ana")) {
			posthog.set_config({ persistence: "localStorage+cookie" })
		} else {
			posthog.set_config({ persistence: "localStorage" })
		}
	}, [cookieOptions])

	useEffect(() => {
		if (cookieOptions?.includes("func")) {
			const script = document.createElement("script")
			script.src = "https://widget.intercom.io/widget/xu1aznd7"
			script.async = true
			document.head.appendChild(script)

			return () => {
				document.head.removeChild(script)
			}
		}
	}, [cookieOptions])

	return (
		<>
			{cookieOptions && !cookieOptions.includes("func") && (
				<LiveChatPlaceholder onClick={() => setLiveChatOpen(true)}>Live chat</LiveChatPlaceholder>
			)}
			<Container $isOpen={!cookieOptions}>
				<Details>
					<Title>We value your privacy</Title>
					<p>
						We use cookies to authenticate you, reach you through our live chat, and to receive visitor
						analytics.
					</p>
				</Details>
				<Button variant="secondary" onClick={() => setCookieOptions(["nec"])}>
					Accept necessary
				</Button>
				<Button variant="secondary" onClick={() => setCustomizeOpen(true)}>
					Customize
				</Button>
				<Button variant="primary" onClick={() => setCookieOptions(["nec", "func", "ana"])}>
					Accept all
				</Button>
			</Container>
			<DefaultModal isOpen={isCustomizeOpen}>
				<ModalTitle>Customize cookies</ModalTitle>
				<ModalText>
					We use cookies to authenticate you, reach you through our live chat, and to receive visitor
					analytics.
				</ModalText>
				<ModalInputs>
					<CustomizeGroup>
						<Checkbox label="Necessary cookies" isChecked isDisabled />
						<p>Some cookies are necessary for user authentication etc.</p>
					</CustomizeGroup>
					<CustomizeGroup>
						<Checkbox
							label="Functional cookies"
							isChecked={isFunctionalChecked}
							onChange={setFunctionalChecked}
						/>
						<p>Functional cookies are required for the live chat to work.</p>
					</CustomizeGroup>
					<CustomizeGroup>
						<Checkbox
							label="Analytics cookies"
							isChecked={isAnalyticsChecked}
							onChange={setAnalyticsChecked}
						/>
						<p>Analytics cookies are used for us to be able to receive visitor analytics.</p>
					</CustomizeGroup>
				</ModalInputs>
				<ModalButtons>
					<Button variant="secondary" onClick={() => setCustomizeOpen(false)}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							const options: CookieOption[] = ["nec"]
							if (isFunctionalChecked) options.push("func")
							if (isAnalyticsChecked) options.push("ana")
							setCookieOptions(options)
							setCustomizeOpen(false)
						}}
					>
						Save
					</Button>
				</ModalButtons>
			</DefaultModal>
			<DefaultModal isOpen={isLiveChatOpen}>
				<ModalTitle>Live chat</ModalTitle>
				<ModalText>In order to use the live chat you need to accept functional cookies.</ModalText>
				<ModalButtons>
					<Button
						variant="secondary"
						onClick={() => {
							setLiveChatOpen(false)
							setCustomizeOpen(true)
						}}
					>
						Customize
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							setCookieOptions([...(cookieOptions ?? []), "func"])
							setLiveChatOpen(false)
						}}
					>
						Accept
					</Button>
				</ModalButtons>
			</DefaultModal>
		</>
	)
}

function useCookieOption(): [CookieOption[] | null, Dispatch<CookieOption[] | null>] {
	function getCookieOption(): CookieOption[] | null {
		const value =
			document.cookie
				.split(";")
				.map(cookiePair => {
					const [key, value] = cookiePair.split("=")
					return { key: key!.trim(), value: decodeURIComponent(value!) }
				})
				.find(x => x.key === cookieName)?.value ?? null

		if (value === null) return null
		const providedOptions = value.split(",").map(x => x.trim())
		const values = options.filter(x => providedOptions.includes(x))
		return values.length > 0 ? values : null
	}

	const [cookieOption, setCookieOption] = useState<CookieOption[] | null>(getCookieOption())

	useEffect(() => {
		if (cookieOption !== null) {
			const domain = CONFIG.isDevelopment ? window.location.hostname : "forento.io"
			document.cookie = `${cookieName}=${cookieOption};max-age=${365 * 24 * 60 * 60};domain=${domain};path=/`
		}
	}, [cookieOption])

	return [cookieOption, setCookieOption]
}

const Container = styled.div<{ $isOpen: boolean }>`
	position: fixed;
	bottom: 24px;
	left: 50%;
	width: calc(100% - 48px);
	max-width: ${pageContentWidth - 60}px;
	transform: translateX(-50%);
	background-color: #fff;
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.18);
	border-radius: 12px;
	padding: 16px 24px;
	display: flex;
	align-items: center;
	gap: 24px;

	${props =>
		!props.$isOpen &&
		css`
			display: none;
		`}
`

const Details = styled.div`
	flex: 1;
`

const Title = styled.h1`
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 4px;
`

const CustomizeGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`

const LiveChatPlaceholder = styled(Button).attrs({ variant: "primary" })`
	position: fixed;
	right: 24px;
	bottom: 24px;
	background-color: ${primaryColor};
`

export default CookieConsent
