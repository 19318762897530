import { type ComponentType } from "react"

import { type Authorization } from "~/App"
import AppSumoSignupPage from "~/pages/account/AppSumoSignupPage"
import CreatePlatformPage from "~/pages/account/CreatePlatformPage"
import NewPasswordPage from "~/pages/account/NewPasswordPage"
import PlanStartedPage from "~/pages/account/PlanStartedPage"
import ResetPasswordPage from "~/pages/account/ResetPasswordPage"
import SelectFindSourcePage from "~/pages/account/SelectFindSourcePage"
import SelectIntentPage from "~/pages/account/SelectIntentPage"
import SelectPlanPage from "~/pages/account/SelectPlanPage"
import SetContactEmailPage from "~/pages/account/SetContactEmailPage"
import SigninPage from "~/pages/account/SigninPage"
import SignupPage from "~/pages/account/SignupPage"
import StartAddonSubscriptionPage from "~/pages/account/StartAddonSubscriptionPage"
import StartPlanSubscriptionPage from "~/pages/account/StartPlanSubscriptionPage"
import VerifyEmailPage from "~/pages/account/VerifyEmailPage"
import VieDeDingueSignupPage from "~/pages/account/VieDeDingueSignupPage"
import BundlesPage from "~/pages/bundle/BundlesPage"
import EditBundlePage from "~/pages/bundle/EditBundlePage"
import CommunityPage from "~/pages/community/CommunityPage"
import CommunityProfilePage from "~/pages/community/CommunityProfilePage"
import ConversationPage from "~/pages/community/ConversationPage"
import EnableCommunityPage from "~/pages/community/EnableCommunityPage"
import CourseInputsPage from "~/pages/course/CourseInputsPage"
import CoursePage from "~/pages/course/CoursePage"
import CourseSettingsPage from "~/pages/course/CourseSettingsPage"
import CoursesPage from "~/pages/course/CoursesPage"
import EnrolledStudentsPage from "~/pages/course/EnrolledStudentsPage"
import ReviewCommentsPage from "~/pages/course/ReviewCommentsPage"
import CreateDownloadablePage from "~/pages/downloadables/CreateDownloadablePage"
import DownloadablesPage from "~/pages/downloadables/DownloadablesPage"
import EditDownloadablePage from "~/pages/downloadables/EditDownloadablePage"
import EventsPage from "~/pages/events/EventsPage"
import ExamCompletionsPage from "~/pages/exam/ExamCompletionsPage"
import ExamPage from "~/pages/exam/ExamPage"
import ExamsPage from "~/pages/exam/ExamsPage"
import AnalyticsPage from "~/pages/main/AnalyticsPage"
import DashboardPage from "~/pages/main/DashboardPage"
import DiscountCodesPage from "~/pages/main/DiscountCodesPage"
import NotFoundPage from "~/pages/main/NotFoundPage"
import ReferralPage from "~/pages/main/ReferralPage"
import WebsiteEditorPage from "~/pages/main/WebsiteEditorPage"
import MembershipPage from "~/pages/membership/MembershipPage"
import CompletingPayPalPage from "~/pages/payments/CompletingPayPalPage"
import CompletingStripePage from "~/pages/payments/CompletingStripePage"
import PaymentsPage from "~/pages/payments/PaymentsPage"
import CreateSessionPage from "~/pages/sessions/CreateSessionPage"
import EditSessionPage from "~/pages/sessions/EditSessionPage"
import SessionsPage from "~/pages/sessions/SessionsPage"
import AccountSettingsPage from "~/pages/settings/AccountSettingsPage"
import BannedIpsSettingsPage from "~/pages/settings/BannedIpsSettingsPage"
import BillingSettingsPage from "~/pages/settings/BillingSettingsPage"
import DomainSettingsPage from "~/pages/settings/DomainSettingsPage"
import EditProductAccessEmail from "~/pages/settings/EditProductAccessEmail"
import ManageDnsPage from "~/pages/settings/ManageDnsPage"
import NotificationsSettingsPage from "~/pages/settings/NotificationsSettingsPage"
import PlatformSettingsPage from "~/pages/settings/PlatformSettingsPage"
import ProductAccessEmails from "~/pages/settings/ProductAccessEmails"
import RegisterDomainPage from "~/pages/settings/RegisterDomainPage"
import SettingsPage from "~/pages/settings/SettingsPage"
import StudentEmailDetailSettingsPage from "~/pages/settings/StudentEmailDetail"
import StudentEmailLayoutSettingsPage from "~/pages/settings/StudentEmailLayout"
import StudentEmailsSettingsPage from "~/pages/settings/StudentEmailsSettingsPage"
import TeamSettingsPage from "~/pages/settings/TeamSettingsPage"
import ZapierSettingsPage from "~/pages/settings/ZapierSettingsPage"
import UserDetailPage from "~/pages/user/UserDetailPage"
import UsersPage from "~/pages/user/UsersPage"
import CreateVideoPage from "~/pages/video/CreateVideoPage"
import EditVideoPage from "~/pages/video/EditVideoPage"
import UploadVideoPage from "~/pages/video/UploadVideoPage"
import VideosPage from "~/pages/video/VideosPage"
import r from "~/utilities/routes"

const appRoutes: { path: string; auth: Authorization; component: ComponentType<object> }[] = [
	{ path: r.main.index(), auth: "require", component: DashboardPage },
	{ path: r.main.analytics(), auth: "require", component: AnalyticsPage },
	{ path: r.main.discountCodes(), auth: "require", component: DiscountCodesPage },
	{ path: r.main.event(), auth: "require", component: EventsPage },
	{ path: r.main.websiteEditor(), auth: "require", component: WebsiteEditorPage },
	{ path: r.payments.index(), auth: "require", component: PaymentsPage },
	{ path: r.payments.completingPayPal(), auth: "require", component: CompletingPayPalPage },
	{ path: r.payments.completingStripe(), auth: "require", component: CompletingStripePage },
	{ path: r.main.referral(), auth: "require", component: ReferralPage },
	{ path: r.account.signin(), auth: "forbid", component: SigninPage },
	{ path: r.account.signup(), auth: "forbid", component: SignupPage },
	{ path: r.account.appSumoSignup(null), auth: "forbid", component: AppSumoSignupPage },
	{ path: r.account.vieDeDingueSignup(), auth: "forbid", component: VieDeDingueSignupPage },
	{ path: r.account.createPlatform(), auth: "require", component: CreatePlatformPage },
	{ path: r.account.selectFindSource(), auth: "require", component: SelectFindSourcePage },
	{ path: r.account.selectIntent(), auth: "require", component: SelectIntentPage },
	{ path: r.account.setContactEmail(), auth: "require", component: SetContactEmailPage },
	{ path: r.account.startPlanSubscriptionPage(), auth: "require", component: StartPlanSubscriptionPage },
	{ path: r.account.startAddonSubscriptionPage(), auth: "require", component: StartAddonSubscriptionPage },
	{ path: r.account.verify(), auth: "none", component: VerifyEmailPage },
	{ path: r.account.selectPlan(), auth: "require", component: SelectPlanPage },
	{ path: r.account.planStarted(), auth: "require", component: PlanStartedPage },
	{ path: r.account.resetPassword(), auth: "none", component: ResetPasswordPage },
	{ path: r.account.newPassword(), auth: "none", component: NewPasswordPage },
	{ path: r.bundle.index(), auth: "require", component: BundlesPage },
	{ path: r.bundle.detail(null), auth: "require", component: EditBundlePage },
	{ path: r.community.index(), auth: "require", component: EnableCommunityPage },
	{ path: r.community.profiles(), auth: "require", component: CommunityPage },
	{ path: r.community.profile(null), auth: "require", component: CommunityProfilePage },
	{ path: r.community.posts(), auth: "require", component: CommunityPage },
	{ path: r.community.channels(), auth: "require", component: CommunityPage },
	{ path: r.community.postCategories(), auth: "require", component: CommunityPage },
	{ path: r.community.leaderboard(), auth: "require", component: CommunityPage },
	{ path: r.community.messages(), auth: "require", component: CommunityPage },
	{ path: r.community.message(null), auth: "require", component: ConversationPage },
	{ path: r.community.settings(), auth: "require", component: CommunityPage },
	{ path: r.course.index(), auth: "require", component: CoursesPage },
	{ path: r.course.detail(null), auth: "require", component: CoursePage },
	{ path: r.course.enrolledStudents(null), auth: "require", component: EnrolledStudentsPage },
	{ path: r.course.inputs(null), auth: "require", component: CourseInputsPage },
	{ path: r.course.reviewComments(null), auth: "require", component: ReviewCommentsPage },
	{ path: r.course.settings(null), auth: "require", component: CourseSettingsPage },
	{ path: r.exam.index(), auth: "require", component: ExamsPage },
	{ path: r.exam.detail(null), auth: "require", component: ExamPage },
	{ path: r.exam.completions(null), auth: "require", component: ExamCompletionsPage },
	{ path: r.downloadable.index(), auth: "require", component: DownloadablesPage },
	{ path: r.downloadable.create(), auth: "require", component: CreateDownloadablePage },
	{ path: r.downloadable.edit(null), auth: "require", component: EditDownloadablePage },
	{ path: r.membership.index(), auth: "require", component: MembershipPage },
	{ path: r.sessions.index(), auth: "require", component: SessionsPage },
	{ path: r.sessions.create(), auth: "require", component: CreateSessionPage },
	{ path: r.sessions.edit(null), auth: "require", component: EditSessionPage },
	{ path: r.settings.index(), auth: "require", component: SettingsPage },
	{ path: r.settings.account(), auth: "require", component: AccountSettingsPage },
	{ path: r.settings.bannedIp(), auth: "require", component: BannedIpsSettingsPage },
	{ path: r.settings.billing.plans(), auth: "require", component: BillingSettingsPage },
	{ path: r.settings.billing.history(), auth: "require", component: BillingSettingsPage },
	{ path: r.settings.billing.paymentMethod(), auth: "require", component: BillingSettingsPage },
	{ path: r.settings.domain.index(), auth: "require", component: DomainSettingsPage },
	{ path: r.settings.domain.register(), auth: "require", component: RegisterDomainPage },
	{ path: r.settings.domain.manageDns(null), auth: "require", component: ManageDnsPage },
	{ path: r.settings.notification(), auth: "require", component: NotificationsSettingsPage },
	{ path: r.settings.platform(), auth: "require", component: PlatformSettingsPage },
	{ path: r.settings.studentEmail.index(), auth: "require", component: StudentEmailsSettingsPage },
	{ path: r.settings.studentEmail.layout(), auth: "require", component: StudentEmailLayoutSettingsPage },
	{ path: r.settings.studentEmail.detail(null), auth: "require", component: StudentEmailDetailSettingsPage },
	{ path: r.settings.studentEmail.productAccess(), auth: "require", component: ProductAccessEmails },
	{ path: r.settings.studentEmail.editProductAccess(null), auth: "require", component: EditProductAccessEmail },
	{ path: r.settings.team(), auth: "require", component: TeamSettingsPage },
	{ path: r.settings.zapier(), auth: "require", component: ZapierSettingsPage },
	{ path: r.user.index(), auth: "require", component: UsersPage },
	{ path: r.user.detail(null), auth: "require", component: UserDetailPage },
	{ path: r.video.index(), auth: "require", component: VideosPage },
	{ path: r.video.create(), auth: "require", component: CreateVideoPage },
	{ path: r.video.upload(null), auth: "require", component: UploadVideoPage },
	{ path: r.video.edit(null), auth: "require", component: EditVideoPage },
	{ path: "*", auth: "none", component: NotFoundPage },
]

export default appRoutes
