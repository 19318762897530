import { type FC } from "react"
import styled from "styled-components"

import { toPriceString } from "@forento/shared/utilities/currency"
import { toNumberFormat } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import { HelpIcon } from "~/components/Icon"
import Layout, { PageHeader, PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useUser } from "~/contexts/UserContext"
import AsideContent from "~/pages/main/DashboardPage/AsideContent"
import Onboarding from "~/pages/main/DashboardPage/Onboarding"
import { cardColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

import FreeTrial from "./FreeTrial"
import Guides from "./Guides"
import StatCard from "./StatCard"

const DashboardPage: FC = () => {
	const user = useUser().user!

	const { data, error } = query.dashboard.get.useQuery()

	const asideData = error
		? { status: "error" as const }
		: data === undefined
			? { status: "loading" as const }
			: {
					status: "success" as const,
					events: data.recentEvents,
					creatorHighlights: data.creatorHighlights,
					productUpdates: data.productUpdates,
				}

	return (
		<Layout asideContent={<AsideContent data={asideData} />}>
			<PageHeader>
				<PageTitle>Dashboard</PageTitle>
				<HelpCenter onClick="https://forento.link/dashboard-help-center" newTab>
					<HelpCenterIcon />
					Help Center
				</HelpCenter>
			</PageHeader>
			<Greeting>Welcome back, {user.firstName}</Greeting>
			{error ? (
				<p>Failed to load dashboard data.</p>
			) : data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<FreeTrial />
					<Onboarding {...data.onboarding} />
					<Cards>
						<StatCard
							label="Sales last 30 days"
							value={toPriceString({ amount: data.sales.value, currency: data.sales.currency })}
							trend={data.sales.differenceFromLast}
						/>
						{data.visits && (
							<StatCard
								label="Visits last 30 days"
								value={toNumberFormat(data.visits.value)}
								trend={data.visits.differenceFromLast}
							/>
						)}
						<Guides />
					</Cards>
				</Content>
			)}
		</Layout>
	)
}

const HelpCenter = styled(Button)`
	color: #7d7d7d;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	display: flex;
	align-items: center;
	gap: 8px;
`

const HelpCenterIcon = styled(HelpIcon)`
	width: 24px;
	height: 24px;
`

const Greeting = styled.p`
	font-size: 36px;
	font-weight: bold;
	margin-bottom: 24px;
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Cards = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
	gap: 16px;

	& > :nth-child(4n + 1) {
		background-color: ${cardColor.blue};
	}
	& > :nth-child(4n + 2) {
		background-color: ${cardColor.orange};
	}
	& > :nth-child(4n + 3) {
		background-color: ${cardColor.purple};
	}
	& > :nth-child(4n + 4) {
		background-color: ${cardColor.gray};
	}
`

export default DashboardPage
