import { useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { AddIcon } from "~/components/Icon"
import { DefaultModal, ModalButtons, ModalContentPadding, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useAlert } from "~/contexts/AlertContext"
import trpc, { query } from "~/utilities/trpc"

interface Props {
	courseId: number
	isOpen: boolean
	onClose(): void
}

const AddTesterModal: React.FC<Props> = ({ courseId, isOpen, onClose }) => {
	const alert = useAlert()

	const users = query.platform.listUsers.useQuery(undefined, { enabled: isOpen })
	const testers = query.course.listTesters.useQuery(courseId, { enabled: isOpen })

	const [submittingUserId, setSubmittingUserId] = useState<number>()

	const handleAddTester = async (userId: number) => {
		setSubmittingUserId(userId)
		try {
			await trpc.course.addTester.mutate({ courseId, userId })
			await Promise.all([users.refetch(), testers.refetch()])
		} catch {
			console.error("Failed to add tester.")
			await alert.show("Error", "Failed to add tester. Please try again later.")
		} finally {
			setSubmittingUserId(undefined)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} width={1000}>
			<ModalTitle>Add tester</ModalTitle>

			{users.error || testers.error ? (
				<p>Failed to load users.</p>
			) : !users.data || !testers.data ? (
				<PartialLoadingPage />
			) : (
				<ModalContentPadding>
					<Table
						isCompact
						columns={["Name", "Email", "Add tester"]}
						emptyText="You have no registered users."
						data={users.data
							.filter(user => !testers.data!.some(tester => tester.userId === user.id))
							.map(user => [
								`${user.firstName} ${user.lastName}`,
								user.email,
								<AddButton
									isLoading={submittingUserId === user.id}
									isDisabled={submittingUserId !== undefined}
									onClick={() => handleAddTester(user.id)}
									replaceOnLoading
								>
									<AddIcon />
								</AddButton>,
							])}
					/>
				</ModalContentPadding>
			)}

			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={submittingUserId !== undefined}>
					Close
				</Button>
			</ModalButtons>
		</DefaultModal>
	)
}

const AddButton = styled(Button)`
	width: 24px;
	height: 24px;
`

export default AddTesterModal
