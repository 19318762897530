export const productTypes = ["course", "video", "downloadable", "session", "event"] as const
export const purchaseableTypes = [...productTypes, "bundle"] as const
export type ProductType = (typeof productTypes)[number]
export type PurchaseableType = (typeof purchaseableTypes)[number]

export const externalPurchaseSources = ["manual", "zapier"] as const
export type ExternalPurchaseSource = (typeof externalPurchaseSources)[number]

type UnlockedCause =
	| { unlockedCause: "free" | "membership" | "creator" }
	| {
			unlockedCause: "purchased"
			order:
				| { type: "stripe" | "paypal"; id: number }
				| { type: "external"; id: number; createdBy: ExternalPurchaseSource }
	  }

export type PersonalizedProductAccess<TUnlocked = object> =
	| { status: "sign-in-required" | "membership-required" | "paused" }
	| { status: "paywall"; price: { amount: number; currency: string } }
	| ({ status: "unlocked" } & TUnlocked & UnlockedCause)
