import { type SubscriptionPaymentInterval, type PlanAccess } from "./payment"
import { type StudentEmailId } from "./studentEmail"

export const platformIntents = ["course", "ebook", "coaching", "community"] as const
export type PlatformIntent = (typeof platformIntents)[number]

export const platformFindSources = [
	"landing",
	"search-engine",
	"friend",
	"blog",
	"influencer",
	"social-media",
	"marketplace",
	"other",
] as const
export type PlatformFindSource = (typeof platformFindSources)[number]

export const themes = [
	{ id: 1, label: "Legacy" },
	{ id: 2, label: "Modern" },
] as const
export type Theme = (typeof themes)[number]["id"]

export type PlatformCommunity = { isGamificationEnabled: boolean } & (
	| { all: true }
	| { all: false; membershipIds: number[] }
)

export interface PublicPlatform {
	id: number
	name: string
	slug: {
		platform: string
		landingPage: string | null
	}
	theme: Theme
	language: PlatformLanguageId
	logo: {
		filePath: string
		isIcon: boolean
	} | null
	title: string | null
	description: string | null
	isSignupsOpen: boolean
	homeLink: string | null
	contactEmail: string | null
	domain: {
		landingPage: string | null
		platform: string
	}
	primaryColor: {
		value: string
		isCustom: boolean
	}
	currency: string
	thumbnailFilePath: string | null
	content: {
		courses: boolean
		exams: boolean
		memberships: boolean
		sessions: boolean
		events: boolean
		videos: boolean
		downloadables: boolean
		bundles: boolean
		certificates: boolean
		multipleChannels: boolean
	}
	paymentGateway: ({ isEnabled: boolean } & ({ type: "stripe" } | { type: "paypal"; id: string })) | null
	community: PlatformCommunity | null
	matomoSiteId: string | null
	isPhoneNumberEnabled: boolean
	hasWatermark: boolean
}

export type PlatformAccess = { [key in PlanAccess]: boolean }

type PlatformPlanBase = {
	commission: number
	access: PlatformAccess
	maxAdmins: number | null
	maxProductAccessEmails: number | null
}

export type FreePlatformPlan = PlatformPlanBase & { type: "free"; id: null }
export type RegularPlatformPlan = PlatformPlanBase & {
	type: "regular"
	id: number
	stripeId: string
	endDate: Date | null
}

type Addon =
	| { type: "trial"; endDate: Date }
	| {
			type: "scale" | "noCommission"
			paymentInterval: SubscriptionPaymentInterval
			stripeId: string
			endDate: Date | null
	  }
export type AppSumoPlatformPlan = PlatformPlanBase & {
	type: "appsumo"
	id: number
	tier: number
	label: string
	addon: Addon | null
}
export type VieDeDinguePlatformPlan = PlatformPlanBase & {
	type: "viededingue"
	id: number
	tier: number
	label: string
	addon: Addon | null
}

export const platformLanguageIds = ["en", "sv", "es", "de", "fr", "it"] as const
export type PlatformLanguageId = (typeof platformLanguageIds)[number]
export const platformLanguages: Record<PlatformLanguageId, string> = {
	en: "English",
	sv: "Swedish",
	es: "Spanish",
	de: "German",
	fr: "French",
	it: "Italian",
}

export const enabledPlatformFeatures = ["invite-to-course", "block-signups", "drip-content", "certificates"] as const

export interface Platform {
	id: number
	name: string
	slug: {
		platform: string
		landingPage: string | null
	}
	theme: { id: Theme; hasChosen: boolean }
	language: PlatformLanguageId
	logo: {
		filePath: string
		isIcon: boolean
	} | null
	faviconPath: string | null
	title: string | null
	description: string | null
	homeLink: string | null
	isSignupsOpen: boolean
	contactEmail: string | null
	domain: {
		landingPage: string | null
		platform: string
		isLegacyDnsHost: boolean
	}
	primaryColor: {
		value: string
		isCustom: boolean
	}
	currency: string
	thumbnailFilePath: string | null
	content: {
		courses: boolean
		exams: boolean
		memberships: boolean
		sessions: boolean
		events: boolean
		videos: boolean
		downloadables: boolean
		bundles: boolean
		certificates: boolean
		multipleChannels: boolean
	}
	enabledStudentEmails: StudentEmailId[]
	paymentGateway:
		| ({ isEnabled: boolean } & (
				| { type: "stripe"; accountType: "standard" | "express"; id: string }
				| { type: "paypal"; id: string; connectDate: Date }
		  ))
		| null
	community: PlatformCommunity | null
	matomoSiteId: string | null
	plan: FreePlatformPlan | RegularPlatformPlan | AppSumoPlatformPlan | VieDeDinguePlatformPlan
	trialEndDate: Date | null
	isTrialDismissed: boolean
	isPhoneNumberEnabled: boolean
	isReferralEnabled: boolean
	hasChosenIntent: boolean
	hasChosenFindSource: boolean
	hasReachedAdminsLimit: boolean
	stripeCustomerId: string | null
	customEmailSender: string | null
	enabledFeatures: (typeof enabledPlatformFeatures)[number][]
	createDate: Date
}

export interface PlatformUcraftUser {
	id: number
	platformId: number
	email: string
	site: {
		id: number
		name: string
		isConfigured: boolean
	} | null
}
