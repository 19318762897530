import { useState, type ComponentType, type FC } from "react"
import { Navigate } from "react-router"
import styled, { css } from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { type PurchaseableType } from "@forento/shared/models/product"

import Button from "~/components/Button"
import {
	BundleIcon,
	CalendarIcon,
	CourseIcon,
	DeleteIcon,
	DownloadIcon,
	EditIcon,
	SessionsIcon,
	VideoIcon,
} from "~/components/Icon"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

import CreateModal from "./CreateModal"

const icons: Record<PurchaseableType, ComponentType> = {
	course: CourseIcon,
	video: VideoIcon,
	downloadable: DownloadIcon,
	event: CalendarIcon,
	session: SessionsIcon,
	bundle: BundleIcon,
}

const ProductAccessEmails: FC = () => {
	const platform = usePlatform()!
	const alert = useAlert()

	const [isCreateModalOpen, setCreateModalOpen] = useState(false)

	const { data: productAccess, error, refetch } = query.studentEmails.listProductAccess.useQuery()

	const deleteEmail = query.studentEmails.deleteProductAccess.useMutation({
		async onSuccess() {
			await refetch()
		},
		async onError(error) {
			console.error(error)
			await alert.show("Error", "Failed to delete product access email. Please try again.")
		},
	})

	if (platform.plan.maxProductAccessEmails === 0) {
		return <Navigate to={routes.settings.studentEmail.index()} replace />
	}

	return (
		<Layout>
			<CreateModal isOpen={isCreateModalOpen} onClose={() => setCreateModalOpen(false)} onReload={refetch} />
			<PageHeader>
				<PageBreadcrumb
					title="Product Access"
					path={[
						{ title: "Settings", link: routes.settings.index() },
						{ title: "Student Emails", link: routes.settings.studentEmail.index() },
					]}
				/>
				{platform.plan.maxProductAccessEmails === null ||
				(productAccess && platform.plan.maxProductAccessEmails > productAccess.length) ? (
					<Button variant="primary" onClick={() => setCreateModalOpen(true)}>
						Create new email
					</Button>
				) : productAccess ? (
					<Tooltip tooltip="You have reached the max number of product access emails">
						<Button variant="primary" isDisabled>
							Create new email
						</Button>
					</Tooltip>
				) : null}
			</PageHeader>
			{error ? (
				<p>Failed to load product access emails.</p>
			) : productAccess === undefined ? (
				<PartialLoadingPage />
			) : (
				<Content>
					{productAccess.map(access => (
						<ProductAccess key={access.id}>
							<ProductAccessIcon as={icons[access.product.type]} />
							<ProductAccessTitle>{access.product.name}</ProductAccessTitle>
							<ProductAccessActions>
								<Tooltip tooltip="Edit email">
									<ProductAccessAction
										onClick={routes.settings.studentEmail.editProductAccess(access.id)}
									>
										<EditIcon />
									</ProductAccessAction>
								</Tooltip>
								<Tooltip tooltip="Delete email">
									<ProductAccessAction
										$isDanger
										onClick={async () => {
											const dialog = await alert.confirm(
												"Delete email",
												"Are you sure you want to delete this email?",
											)
											if (!dialog.result) return
											await deleteEmail.mutateAsync(access.id)
											dialog.close()
										}}
									>
										<DeleteIcon />
									</ProductAccessAction>
								</Tooltip>
							</ProductAccessActions>
						</ProductAccess>
					))}
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const ProductAccess = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
	display: grid;
	gap: 16px;
	grid-template-columns: max-content 1fr max-content;
	align-items: center;
`

const ProductAccessIcon = styled.div`
	width: 24px;
	height: 24px;
`

const ProductAccessTitle = styled.h2`
	font-weight: 600;
	font-size: 20px;
	text-align: left;
`

const ProductAccessActions = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const ProductAccessAction = styled(Button)<{ $isDanger?: boolean }>`
	width: 24px;
	height: 24px;

	${props =>
		props.$isDanger &&
		css`
			color: ${dangerColor};
		`}
`

export default ProductAccessEmails
