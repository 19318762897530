import styled from "styled-components"

import Button from "~/components/Button"
import { OpenIcon, TrendIcon } from "~/components/Icon"
import routes from "~/utilities/routes"
import { dangerColor, successColor } from "~/utilities/styles"

type Props = { label: string; value: string; trend: number }
const StatCard: React.FC<Props> = ({ label, value, trend }) => {
	const trendDirection = trend > 0 ? "up" : trend < 0 ? "down" : "flat"

	return (
		<Container onClick={routes.main.analytics()}>
			<Header>
				<Label>{label}</Label>
				<LinkIcon />
			</Header>
			<Content>
				<Value>{value}</Value>
				<Trend $direction={trendDirection}>
					<StyledTrendIcon direction={trendDirection} />
					{Math.floor(trend * 100)}%
				</Trend>
			</Content>
		</Container>
	)
}

const Container = styled(Button)`
	border-radius: 24px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`

const Content = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`

const Label = styled.p`
	font-weight: 500;
	font-size: 16px;
`

const LinkIcon = styled(OpenIcon)`
	width: 24px;
	height: 24px;
`

const Value = styled.p`
	font-weight: 800;
	font-size: 36px;
`

const Trend = styled.div<{ $direction: "up" | "flat" | "down" }>`
	background-color: white;
	font-weight: 600;
	font-size: 16px;
	color: ${props => (props.$direction === "up" ? successColor : props.$direction === "down" ? dangerColor : "black")};
	border-radius: 8px;
	padding: 6px;
	display: flex;
	align-items: center;
	gap: 4px;
`

const StyledTrendIcon = styled(TrendIcon)`
	width: 16px;
	height: 16px;
`

export default StatCard
