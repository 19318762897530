import mime from "mime"
import papa from "papaparse"
import { type FC, useState } from "react"
import { useDropzone } from "react-dropzone"
import readXlsxFile from "read-excel-file"
import styled from "styled-components"

import { toDistinctArray } from "@forento/shared/utilities/array"
import { supportedFileExtensions } from "@forento/shared/utilities/file"
import regex from "@forento/shared/utilities/regex"

import { UploadFileIcon } from "~/components/Icon"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { primaryColor } from "~/utilities/styles"

async function parseFile(file: File): Promise<string[][]> {
	if (file.type === mime.getType("csv")) {
		return papa
			.parse(await file.text(), { skipEmptyLines: "greedy" })
			.data.map(row => (row instanceof Object ? (Object.values(row) as string[]) : (row as string[])))
	} else {
		return (await readXlsxFile(file)).map(row => row.map(cell => cell.toString()))
	}
}

type Props = { onEmailsUploaded(emails: string[]): void }
const Uploader: FC<Props> = ({ onEmailsUploaded }) => {
	const alert = useAlert()

	const [isUploading, setUploading] = useState(false)

	const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
		accept: Object.fromEntries(supportedFileExtensions.spreadsheet.map(extension => [mime.getType(extension), []])),
		async onDrop(acceptedFiles: File[]) {
			const file = acceptedFiles[0]
			if (file === undefined) return

			setUploading(true)

			if (file.size > 400 * 1024 * 1024) {
				await alert.show(
					"File too large",
					"The file is too large. It can be a maximum of 400 MB. Please upload a smaller file.",
				)
				return
			}

			const data = await parseFile(file)
			if (data.length === 0) {
				await alert.show("Invalid spreadsheet", "The spreadsheet is empty.")
				setUploading(false)
				return
			}
			if (data.some(row => row.length > 1)) {
				await alert.show(
					"Invalid spreadsheet",
					"You need to have only one column in the spreadsheet with emails.",
				)
				setUploading(false)
				return
			}

			const emails = toDistinctArray(
				data.map(row => row[0].trim().toLowerCase()).filter(row => row.length > 0 && regex.email.test(row)),
			)
			if (emails.length === 0) {
				await alert.show("Invalid spreadsheet", "The spreadsheet doesn't contain any valid emails.")
				setUploading(false)
				return
			}

			setUploading(false)
			onEmailsUploaded(emails)
		},
	})

	return (
		<Dropzone {...getRootProps()}>
			{!isUploading && <input {...getInputProps()} />}
			{isUploading ? (
				<PartialLoadingPage />
			) : isDragActive ? (
				<InfoText>{isDragReject ? "That file type is not supported" : `Drop spreadsheet file here`}</InfoText>
			) : (
				<>
					<Icon as={UploadFileIcon} />
					<InfoText>Click here or drag and drop to upload a spreadsheet</InfoText>
				</>
			)}
		</Dropzone>
	)
}

const Dropzone = styled.div`
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 32px;
	cursor: pointer;
	position: relative;
`

const Icon = styled.div`
	width: 64px;
	height: 64px;
	color: ${primaryColor};
`

const InfoText = styled.p`
	font-weight: 500;
	font-size: 16px;
	text-align: center;
`

export default Uploader
