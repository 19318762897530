import { type FC, useEffect, useState } from "react"

import Button from "~/components/Button"
import InputField, { useRichTextArea } from "~/components/InputField"
import { useAlert } from "~/contexts/AlertContext"

import Attachments from "./Attachments"
import Links from "./Links"
import { ButtonsGroup, ButtonsRow } from "./styles"

type Props = {
	page: {
		id: number
		title: string
		text: string | null
		vimeoVideoId: string | null
		attachments: { id: number; fileType: string; label: string; url: string }[]
		links: { id: number; label: string; url: string }[]
	}
	isSaving: boolean
	isDeleting: boolean
	onClose(): void
	onSavePage(data: { title: string; text: string | null; vimeoVideoId: string }): void
	onDeletePage(): void
}

const EditVimeoPageModalContent: FC<Props> = ({ page, isSaving, isDeleting, onClose, onSavePage, onDeletePage }) => {
	const alert = useAlert()

	const [title, setTitle] = useState(page.title)
	const text = useRichTextArea({ label: "Text", initialValue: page.text, features: { headings: true, colors: true } })
	const [vimeoVideoUrl, setVimeoVideoUrl] = useState("")

	useEffect(() => {
		setTitle(page.title)
		setVimeoVideoUrl(page.vimeoVideoId ? `https://vimeo.com/${page.vimeoVideoId}` : "")
	}, [page])

	async function handleSave() {
		const regExp =
			/(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/

		const match = vimeoVideoUrl.match(regExp)
		const vimeoVideoId = match?.[1]
		if (vimeoVideoId === undefined) {
			await alert.show(
				"Invalid Vimeo URL",
				"You have provided an invalid Vimeo URL. Please copy the URL from the address bar when watching a Vimeo video.",
			)
			return
		}

		onSavePage({ title, text: text.exportEditorState(), vimeoVideoId })
	}

	return (
		<>
			<InputField label="Title" value={title} onChange={setTitle} />
			{text.element}
			<Attachments pageId={page.id} attachments={page.attachments} />
			<Links pageId={page.id} links={page.links} />
			<InputField label="Vimeo URL" value={vimeoVideoUrl} onChange={setVimeoVideoUrl} />
			<ButtonsRow>
				<ButtonsGroup>
					<Button variant="secondary" onClick={onClose} isDisabled={isSaving || isDeleting}>
						Cancel
					</Button>
					<Button
						variant="primary"
						isDisabled={title.trim().length === 0 || isDeleting}
						isLoading={isSaving}
						onClick={handleSave}
					>
						Save
					</Button>
				</ButtonsGroup>
				<Button variant="primary-danger" onClick={onDeletePage} isDisabled={isSaving} isLoading={isDeleting}>
					Delete
				</Button>
			</ButtonsRow>
		</>
	)
}

export default EditVimeoPageModalContent
