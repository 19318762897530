import { Suspense, type FC, lazy } from "react"
import styled from "styled-components"

import InputLabel from "~/components/InputLabel"

const Editor = lazy(() => import("@monaco-editor/react"))

type Props = { label?: string; defaultValue: string; onChange(value: string): void }
const CodeEditor: FC<Props> = ({ label, defaultValue, onChange }) => (
	<Container>
		{label && <InputLabel>{label}</InputLabel>}
		<EditorContainer>
			<Suspense fallback={<p>Loading editor...</p>}>
				<Editor
					height={500}
					defaultLanguage="html"
					defaultValue={defaultValue}
					onChange={content => onChange(content ?? "")}
					options={{ scrollBeyondLastLine: false, wordWrap: "on" }}
				/>
			</Suspense>
		</EditorContainer>
	</Container>
)

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

const EditorContainer = styled.div`
	width: 100%;
	border: 1px solid #d0d5dd;
	border-radius: 8px;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	overflow: hidden;
`

export default CodeEditor
