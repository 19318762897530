import { type FC } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

import { supportedFileExtensions } from "@forento/shared/utilities/file"

import { UploadIcon } from "~/components/Icon"
import { dangerColor, primaryColor } from "~/utilities/styles"

type Props = { onImageDrop(file: File): void }
const Dropzone: FC<Props> = ({ onImageDrop }) => {
	const handleImageDrop = async (acceptedFiles: File[]) => {
		const file = acceptedFiles?.[0]
		if (file === undefined) return

		onImageDrop(file)
	}

	const imageDropzone = useDropzone({
		onDrop: handleImageDrop,
		multiple: false,
		accept: Object.fromEntries(supportedFileExtensions.image.map(x => [`image/${x.toLowerCase()}`, []])),
	})

	return (
		<Container {...imageDropzone.getRootProps()}>
			<input {...imageDropzone.getInputProps()} />
			{!imageDropzone.isDragActive ? (
				<>
					<UploadIconContainer>
						<StyledUploadIcon />
					</UploadIconContainer>
					<InfoText>
						<InfoFocusText>Click to upload</InfoFocusText> or drag and drop
					</InfoText>
				</>
			) : !imageDropzone.isDragReject ? (
				<DropText>Drop file to upload</DropText>
			) : (
				<CanNotDropText>That file type is not supported</CanNotDropText>
			)}
		</Container>
	)
}

const Container = styled.div<{ hasFile?: boolean }>`
	height: 128px;
	background-color: ${props => (props.hasFile ? "white" : "#f9f8ff")};
	border: 1px solid ${props => (props.hasFile ? primaryColor : "#efefef")};
	border-radius: 8px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	cursor: pointer;
`

const UploadIconContainer = styled.div`
	flex: 0 0 46px;
	width: 46px;
	background-color: #edebf3;
	border: 6px solid #f6f3ff;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const StyledUploadIcon = styled(UploadIcon)`
	width: 20px;
	height: 20px;
	color: ${primaryColor};
`

const InfoText = styled.p`
	font-size: 14px;
	color: #595959;
`

const InfoFocusText = styled.span`
	font-weight: 600;
	color: ${primaryColor};
`
const DropText = styled.p`
	color: ${primaryColor};
`

const CanNotDropText = styled.p`
	color: ${dangerColor};
`

export default Dropzone
